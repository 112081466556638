<template>
  <!-- 修改售卖房型 (携程渠道)-->
  <div>
    <el-dialog
      title="修改售卖房型"
      :visible.sync="dialogFormVisible"
      top="10rem"
      :close-on-click-modal="false"
      width="750px"
    >
      <el-row>
        <el-col class="leftlabelbox" :span="4">售卖房型名称</el-col>
        <el-col :span="20">
          <el-input
            size="mini"
            style="width: 210px"
            readonly
            v-model="defaultData.roomName"
          ></el-input>
        </el-col>
      </el-row>
      <el-row class="margintop20">
        <el-col class="leftlabelbox" :span="4">适用日期</el-col>
        <el-col :span="20">
          <el-date-picker
            size="mini"
            unlink-panels
            class="width200"
            style="width: 210px"
            :clearable="false"
            v-model="chooseDate"
            type="daterange"
            range-separator="-"
             value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-col>
      </el-row>
      <el-row class="margintop20">
        <el-col class="leftlabelbox" :span="4">适用星期</el-col>
        <el-col :span="20">
          <el-checkbox-group  @change="cheSi" v-model="checkedWeeks">
            <el-checkbox
              v-for="item in weeksList"
              :label="item.id"
              :key="item.id"
              >{{ item.week }}</el-checkbox
            >
          </el-checkbox-group>
        </el-col>
      </el-row>
      <el-row class="margintop20">
        <el-col class="leftlabelbox" :span="4">房态</el-col>
        <el-col class="height28" :span="20">
          <el-radio-group size="mini" v-model="defaultData.state">
            <el-radio :label="'不变'">不变</el-radio>
            <el-radio :label="1">开房</el-radio>
            <el-radio :label="0">关房</el-radio>
            <el-radio :label="2">限量售卖</el-radio>
          </el-radio-group>
        </el-col>
      </el-row>
      <el-row class="margintop20">
        <el-col class="leftlabelbox" :span="4">房价</el-col>
        <el-col :span="20">
          <el-radio-group size="mini" v-model="priceDisable">
            <el-radio :label="'不变'">不变</el-radio>
            <el-radio :label="1">底价</el-radio>
          </el-radio-group>
          <el-input
            size="mini"
            class="marginleft10"
            style="width: 60px"
            v-model="defaultData.purchasePrice"
          >
          </el-input>
          <span class="marginleft10">元</span>
        </el-col>
      </el-row>
      <el-row class="margintop20">
        <el-col class="leftlabelbox" :span="4">房价固定利润公式</el-col>
        <el-col :span="20">
          <el-select size="mini" style="width: 88px" v-model="shiyongDisable">
            <el-option label="不变" value="不变"></el-option>
            <el-option label="修改为" value="修改为"></el-option>
          </el-select>
          <span v-if="shiyongDisable == '修改为'" class="marginleft10"
            >=采购价</span
          >
          <el-select
            v-if="shiyongDisable == '修改为'"
            size="mini"
            class="marginleft10"
            style="width: 88px"
            v-model="defaultData.formula"
          >
            <el-option label="+" value="+"></el-option>
            <el-option label="-" value="-"></el-option>
            <el-option label="*" value="*"></el-option>
            <el-option label="÷" value="÷"></el-option>
            <el-option label="空" value=""></el-option>
          </el-select>
          <el-input
            v-if="shiyongDisable == '修改为'"
            size="mini"
            style="width: 88px"
            v-model="defaultData.ratio"
            class="marginleft10"
            placeholder="1"
          >
          </el-input>
        </el-col>
      </el-row>
      <el-row class="margintop20">
        <el-col class="leftlabelbox" :span="4">立即确定房量</el-col>
        <el-col :span="20">
          <el-select
            size="mini"
            style="width: 88px"
            v-model="defaultData.roomAction"
          >
            <el-option label="不变" value="不变"></el-option>
            <el-option label="加房" value="1"></el-option>
            <el-option label="减房" value="2"></el-option>
            <el-option label="总量等于" value="3"></el-option>
            <el-option label="余量等于" value="4"></el-option>
          </el-select>
          <el-input
            size="mini"
            v-model="defaultData.roomAllotment"
            style="width: 88px"
            class="marginleft10"
            placeholder="1"
          >
          </el-input>
          <span class="marginleft10">间</span>
        </el-col>
      </el-row>

      <el-row class="margintop20">
        <el-col class="leftlabelbox" :span="4">最晚立即确认时间</el-col>
        <el-col :span="20">
          <el-select size="mini" style="width: 88px" v-model="confirmDay">
            <el-option label="不变" value="不变"></el-option>
            <el-option label="修改为" value="修改为"></el-option>
          </el-select>
          <el-input
          v-if="confirmDay=='修改为'"
            size="mini"
            style="width: 88px"
            class="marginleft10"
            v-model="defaultData.confirmDay"
            placeholder="1"
          >
          </el-input>
          <span class="marginleft10"   v-if="confirmDay=='修改为'">天前的</span>
          <el-select
            v-if="confirmDay=='修改为'"
            size="mini"
            style="width: 88px"
            class="marginleft10"
            v-model="defaultData.allotmentLastReserveTime"
          >
            <el-option
              v-for="item in timers"
              :key="item.id"
              :label="item.label"
              :value="item.label"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>

      <el-row class="margintop20">
        <el-col class="leftlabelbox" :span="4">最晚预定时间</el-col>
        <el-col :span="20">
          <el-select size="mini" style="width: 88px" v-model="subscribeDay">
            <el-option label="不变" value="不变"></el-option>
            <el-option label="修改为" value="修改为"></el-option>
          </el-select>
          <el-input
          v-if="subscribeDay=='修改为'"
            size="mini"
            style="width: 88px"
            class="marginleft10"
            v-model="defaultData.subscribeDay"
            placeholder="1"
          >
          </el-input>
          <span  v-if="subscribeDay=='修改为'" class="marginleft10">天前的</span>
          <el-select
           v-if="subscribeDay=='修改为'"
            size="mini"
            style="width: 88px"
            class="marginleft10"
            v-model="defaultData.subscribeTime"
          >
            <el-option
              v-for="item in timers"
              :key="item.id"
              :label="item.label"
              :value="item.label"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row class="margintop20">
        <el-col class="leftlabelbox" :span="4"> 取消规则 </el-col>
        <el-col :span="20">
          <el-select
            size="mini"
            style="width: 88px"
            v-model="defaultData.cancelType"
          >
            <el-option label="不变" value="不变"></el-option>
            <el-option label="免费取消" value="0"></el-option>
            <el-option label="限时取消" value="1"></el-option>
            <el-option label="不可取消" value="2"></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row class="margintop20" v-if="defaultData.cancelType==1">
        <el-col class="leftlabelbox" :span="4"> 最晚取消时间 </el-col>
        <el-col :span="20">
          <el-select
            size="mini"
            style="width: 120px"
            v-model="defaultData.cancelDay"
          >
            <el-option
              v-for="item in cancelDayList"
              :key="item.id"
              :label="item.label"
              :value="item.id"
            ></el-option>
          </el-select>
          <el-select
            size="mini"
            style="width: 88px"
            class="marginleft10"
            v-model="defaultData.cancelTime"
          >
            <el-option
              v-for="item in timers"
              :key="item.id"
              :label="item.label"
              :value="item.label"
            ></el-option>
          </el-select>
          <span class="marginleft10">不扣款</span>
        </el-col>
      </el-row>
      <el-row class="margintop20" v-if="defaultData.cancelType==1">
        <el-col class="leftlabelbox" :span="4"> 逾期扣款 </el-col>
        <el-col :span="20" class="height28">
          <el-radio-group
            class="marginright10"
            size="mini"
            v-model="defaultData.penaltyBasicStandard"
          >
            <el-radio :label="0">扣全款</el-radio>
            <el-radio :label="1">扣首日</el-radio>
          </el-radio-group>
        </el-col>
      </el-row>

      <div class="diafooterbtn">
        <el-button
          @click="cleanFn"
          style="width: 84px; margin-right: 10%"
          size="small"
          type="primary"
          plain
          >取消</el-button
        >
        <el-button
          style="width: 84px"
          @click="handEditData"
          size="small"
          type="primary"
          >保存</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { upDltRp_api } from "../../../apis/room";
export default {
  data() {
    return {
      dialogFormVisible: false,

      defaultData: {
        roomName: "",
        startTime: "",
        endTime: "",
        formula: "+",
        purchasePrice: "",
        ratio: "",
        roomAction: "不变",
        roomAllotment: "",
        roomId: "",
        roomVestId: "",
        state: "不变",
        week: "",
        allotmentLastReserveTime: "", //1最晚立即确认时间 HH:MM
        cancelDay: "", //最晚取消N天前
        cancelTime: "", //最晚取消时间 HH:MM
        cancelType: "不变", //0为免费取消 1为限时取消 2为不可取消
        confirmDay: "", //1最晚立即确认N天前
        penaltyBasicStandard: "", //逾期扣款 0全部/1首日/2百分比/3限时免费/4固定金额
        subscribeDay: "", //2最晚预订N天前
        subscribeTime: "", //2最晚预订时间 HH:MM
      },
      chooseDate: [new Date(), new Date()],
      shiyongDisable: "不变",
       priceDisable: "不变",
       confirmDay: "不变",
       subscribeDay: "不变",

      weeksList: [
        { week: "周一", id: 1 },
        { week: "周二", id: 2 },
        { week: "周三", id: 3 },
        { week: "周四", id: 4 },
        { week: "周五", id: 5 },
        { week: "周六", id: 6 },
        { week: "周日", id: 0},
      ],
      timers: [
        { label: "00:00", id: 1 },
        { label: "01:00", id: 2 },
        { label: "02:00", id: 3 },
        { label: "03:00", id: 4 },
        { label: "04:00", id: 5 },
        { label: "05:00", id: 6 },
        { label: "06:00", id: 7 },
        { label: "07:00", id: 8 },
        { label: "08:00", id: 9 },
        { label: "09:00", id: 10 },
        { label: "10:00", id: 11 },
        { label: "11:00", id: 12 },
        { label: "12:00", id: 13 },
        { label: "13:00", id: 14 },
        { label: "14:00", id: 15 },
        { label: "15:00", id: 16 },
        { label: "16:00", id: 17 },
        { label: "17:00", id: 18 },
        { label: "18:00", id: 19 },
        { label: "19:00", id: 20 },
        { label: "20:00", id: 21 },
        { label: "21:00", id: 22 },
        { label: "22:00", id: 23 },
        { label: "23:00", id: 24 },
        { label: "24:00", id: 25 },
        { label: "25:00", id: 26 },
        { label: "26:00", id: 27 },
        { label: "27:00", id: 28 },
        { label: "28:00", id: 29 },
        { label: "29:00", id: 30 },
        { label: "30:00", id: 31 },
      ],
      cancelDayList: [],
      checkedWeeks: [1,2,3,4,5,6,0],
    };
  },
  created() {
    this.cancelDayList = [];
    for (let i = 0; i < 90; i++) {
      let data = { label: `入住前${i}天`, id: i };
      this.cancelDayList.push(data);
    }
  },
  methods: {
    open(roomName, roomId, roomVestId, platform,effectiveDate) {
      this.defaultData.roomName = roomName;
      this.defaultData.roomId = roomId;
      this.defaultData.roomVestId = roomVestId;
      this.platform = platform;
      this.dialogFormVisible = true;
      if(effectiveDate){
        this.chooseDate=[effectiveDate,effectiveDate]
      }
    },
    
      cheSi(val) {
      console.log(val);
    },

    // 取消
    cleanFn() {
      this.dialogFormVisible = false;
    },
    // 提交
    handEditData() {
       if (this.checkedWeeks.length > 0) {
        this.defaultData.week = this.checkedWeeks.join(",");
      }
      if (this.defaultData.state == "不变") {
        this.defaultData.state = "";
      }
      if (this.priceDisable == "不变") {
        this.defaultData.purchasePrice = "";
      }
      if (this.shiyongDisable == "不变") {
        this.defaultData.formula = "";
        this.defaultData.ratio = "";
      }
      if(this.confirmDay=="不变"){
        this.defaultData.confirmDay=''
       this.defaultData.allotmentLastReserveTime=''
      }
      if(this.subscribeDay=='不变'){
       this.defaultData.subscribeDay=''
       this.defaultData.subscribeTime=''
      }
      if (this.defaultData.roomAction == "不变") {
        this.defaultData.roomAction = "";
        this.defaultData.roomAllotment = "";
      }
      if(this.defaultData.cancelType == "不变"){
        this.defaultData.cancelType=''
        this.defaultData.cancelDay=''
        this.defaultData.cancelTime=''

      }

      this.defaultData.startTime = this.initDate(this.chooseDate[0]);
      this.defaultData.endTime = this.initDate(this.chooseDate[1]);
      this.upDltRp()
    },
    //适用星期
    handleCheckedWeeksChange(val) {
      console.log(val);
    },
     //格式化时间
    initDate(date) {
      date=new Date(date)

      let year = date.getFullYear();
      let mouth = date.getMonth() + 1;
      if (mouth < 10) {
        mouth= "0" + mouth;
      }
      let day = date.getDate();
      if (day < 10) {
       day= "0" + day;
      }
      return `${year}-${mouth}-${day}`;
    },
    async upDltRp(){
      let {code,data}= await upDltRp_api(this.defaultData)
       if (code == 0) {
        this.$message.success(data ? data : "提交成功");
        this.dialogFormVisible = false;
         this.$parent.getRoomInfoById()
      }

    }
  },
};
</script>

<style lang="less" scoped>
.diafooterbtn {
  margin-top: 30px;
  text-align: center;
}
.margintop20 {
  margin-top: 20px;
}
.leftlabelbox {
  text-align: right;
  padding-right: 20px;
  min-height: 28px;
  line-height: 28px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
}
.marginleft10 {
  margin-left: 10px;
}
.height28 {
  height: 28px;
  line-height: 28px;
}
</style>>

