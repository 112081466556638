<template>
  <!-- 修改售卖房型 (除了携程的其他渠道)-->
  <div>
    <el-dialog title="修改售卖房型" :visible.sync="dialogFormVisible" top="10rem" :close-on-click-modal="false"
      @closed="cleanDataFn" width="750px">
      <el-row>
        <el-col class="leftlabelbox" :span="4">售卖房型名称</el-col>
        <el-col :span="20">
          <el-input size="mini" style="width: 210px" readonly v-model="defaultData.roomName"></el-input>
        </el-col>
      </el-row>
      <el-row class="margintop20">
        <el-col class="leftlabelbox" :span="4">适用日期</el-col>
        <el-col :span="20">
          <el-date-picker size="mini" unlink-panels class="width200" style="width: 210px" :clearable="false"
            v-model="chooseDate" type="daterange" range-separator="-" value-format="yyyy-MM-dd" start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-col>
      </el-row>
      <el-row class="margintop20">
        <el-col class="leftlabelbox" :span="4">适用星期</el-col>
        <el-col :span="20">
          <el-checkbox-group @change="cheSi" v-model="checkedWeeks">
            <el-checkbox v-for="item in weeksList" :label="item.id" :key="item.id">{{ item.week }}</el-checkbox>
          </el-checkbox-group>
        </el-col>
      </el-row>
      <el-row class="margintop20">
        <el-col class="leftlabelbox" :span="4">房态</el-col>
        <el-col class="height28" :span="20">
          <el-radio-group size="mini" v-model="defaultData.state">
            <el-radio :label="''">不变</el-radio>
            <el-radio :label="1">开房</el-radio>
            <el-radio :label="0">关房</el-radio>
            <el-radio :label="2" v-if="platform == 1">限量售卖</el-radio>
          </el-radio-group>
        </el-col>
      </el-row>
      <el-row class="margintop20">
        <el-col class="leftlabelbox" :span="4">房价</el-col>
        <el-col :span="20">
          <el-radio-group size="mini" v-model="priceDisable">
            <el-radio :label="''">不变</el-radio>
            <el-radio :label="1">底价</el-radio>
          </el-radio-group>
          <el-input v-if="priceDisable == 1" size="mini" class="marginleft10" style="width: 60px"
            v-model="defaultData.purchasePrice">
          </el-input>
          <span v-if="priceDisable == 1" class="marginleft10">元</span>
        </el-col>
      </el-row>
      <el-row class="margintop20">
        <el-col class="leftlabelbox" :span="4">房价固定利润公式</el-col>
        <el-col :span="20">
          <el-select size="mini" style="width: 88px" v-model="shiyongDisable">
            <el-option label="不变" value="不变"></el-option>
            <el-option label="修改为" value="修改为"></el-option>
          </el-select>
          <span v-if="shiyongDisable == '修改为'" class="marginleft10">=采购价</span>
          <el-select v-if="shiyongDisable == '修改为'" size="mini" class="marginleft10" style="width: 88px"
            v-model="defaultData.formula">
            <el-option label="+" value="+"></el-option>
            <el-option label="-" value="-"></el-option>
            <el-option label="*" value="*"></el-option>
            <el-option label="÷" value="÷"></el-option>
            <el-option label="空" value=""></el-option>
          </el-select>
          <el-input v-if="shiyongDisable == '修改为'" size="mini" v-model="defaultData.ratio" style="width: 88px"
            class="marginleft10" placeholder="1">
          </el-input>
        </el-col>
      </el-row>
      <el-row class="margintop20">
        <el-col class="leftlabelbox" :span="4">房量库存</el-col>
        <el-col :span="20">
          <el-select size="mini" style="width: 88px" v-model="defaultData.roomAction">
            <el-option label="不变" value=""></el-option>
            <el-option label="加房" value="1"></el-option>
            <el-option label="减房" value="2"></el-option>
            <el-option label="总量等于" value="3"></el-option>
            <el-option label="余量等于" value="4"></el-option>
          </el-select>
          <el-input v-if="defaultData.roomAction != ''" size="mini" v-model="defaultData.roomAllotment"
            style="width: 88px" class="marginleft10" placeholder="1">
          </el-input>
          <span v-if="defaultData.roomAction != ''" class="marginleft10">间</span>
        </el-col>
      </el-row>
      <el-row class="margintop20">
        <el-col class="leftlabelbox" :span="4">保留房</el-col>
        <el-col :span="20">
          <el-input size="mini" style="width: 88px" v-model="defaultData.alQuota"></el-input>
        </el-col>
      </el-row>

      <div class="diafooterbtn">
        <el-button @click="cleanFn" style="width: 84px; margin-right: 10%" size="small" type="primary"
          plain>取消</el-button>
        <el-button style="width: 84px" @click="handEditData" size="small" type="primary">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { upYiLongRp_api, upFlyPigRp_api, upMeiTuanRp_api, upQunarRp_api, upBaiduRp_api,upLuoboRp_api,upFctxRp_api } from "../../../apis/room";

export default {
  data() {
    return {
      dialogFormVisible: false,
      platform: "",
      chooseDate: [new Date(), new Date()],
      priceDisable: "",

      defaultData: {
        roomName: "",
        startTime: "",
        endTime: "",
        formula: "+",
        purchasePrice: "",
        ratio: "",
        roomAction: "",
        roomAllotment: "",
        roomIds: "",
        roomVestIds: "",
        state: "",
        week: "",
        alQuota: '',
      },
      shiyongDisable: "不变",
      weeksList: [
        { week: "周一", id: 1 },
        { week: "周二", id: 2 },
        { week: "周三", id: 3 },
        { week: "周四", id: 4 },
        { week: "周五", id: 5 },
        { week: "周六", id: 6 },
        { week: "周日", id: 0 },
      ],
      checkedWeeks: [1, 2, 3, 4, 5, 6, 0],
    };
  },
  created() { },
  methods: {

    open(roomName, roomId, roomVestId, platform, effectiveDate) {
      this.defaultData.roomName = roomName;
      this.defaultData.roomIds = roomId;
      this.defaultData.roomVestIds = roomVestId;

      this.platform = platform;
      this.dialogFormVisible = true;
      if (effectiveDate) {
        this.chooseDate = [effectiveDate, effectiveDate]
        //  this.chooseDate=[]
        //  this.chooseDate.push(effectiveDate)
        //  this.chooseDate.push(effectiveDate)
      }
    },


    cheSi(val) {
      console.log(val);
    },
    // 取消
    cleanFn() {
      this.dialogFormVisible = false;
    },
    // 提交
    handEditData() {
      if (this.checkedWeeks.length > 0) {
        this.defaultData.week = this.checkedWeeks.join(",");
      }
      if (this.defaultData.state == "不变") {
        this.defaultData.state = "";
      }
      if (this.priceDisable == "不变" || this.priceDisable == "") {
        this.defaultData.purchasePrice = "";
      }
      if (this.shiyongDisable == "不变") {
        this.defaultData.formula = "";
        this.defaultData.ratio = "";
      }
      if (this.defaultData.roomAction == "不变") {
        this.defaultData.roomAction = "";
        this.defaultData.roomAllotment = "";
      }

      this.defaultData.startTime = this.initDate(this.chooseDate[0]);
      this.defaultData.endTime = this.initDate(this.chooseDate[1]);

      //  console.log("提交", this.platform);
      if (this.platform == 1) {
        this.upYiLongRp();
      } else if (this.platform == 2) {
        this.upFlyPigRp();
      } else if (this.platform == 3) {
        this.upMeiTuanRp();
      } else if (this.platform == 4) {
        this.upQunarRp();
      } else if (this.platform == 6) {
        this.upBaiduRp();
      }else if (this.platform == 7) {
        this.upLuoboRp();
      }else if (this.platform == 8) {
        this.upFctxRp();
      }
    },
    //格式化时间
    initDate(date) {
      date = new Date(date);

      let year = date.getFullYear();
      let mouth = date.getMonth() + 1;
      if (mouth < 10) {
        mouth = "0" + mouth;
      }
      let day = date.getDate();
      if (day < 10) {
        day = "0" + day;
      }
      return `${year}-${mouth}-${day}`;
    },
    cleanDataFn() {
      Object.assign(this.$data, this.$options.data());
    },
    //艺龙渠道提交
    async upYiLongRp() {
      //   console.log(this.defaultData.toString());
      let { code, data } = await upYiLongRp_api(this.defaultData);
      if (code == 0) {
        this.$message.success(data ? data : "提交成功");
        this.dialogFormVisible = false;
        this.$parent.getRoomInfoById()
      }
    },
    //飞猪渠道提交
    async upFlyPigRp() {
      let { code, data } = await upFlyPigRp_api(this.defaultData);
      if (code == 0) {
        this.$message.success(data ? data : "提交成功");
        this.dialogFormVisible = false;
        this.$parent.getRoomInfoById()
      }
    },
    //美团渠道提交
    async upMeiTuanRp() {
      let { code, data } = await upMeiTuanRp_api(this.defaultData);
      if (code == 0) {
        this.$message.success(data ? data : "提交成功");
        this.dialogFormVisible = false;
        this.$parent.getRoomInfoById()
      }
    },
    //去哪儿渠道提交
    async upQunarRp() {
      let { code, data } = await upQunarRp_api(this.defaultData);
      if (code == 0) {
        this.$message.success(data ? data : "提交成功");
        this.dialogFormVisible = false;
        this.$parent.getRoomInfoById()
      }
    },
    //百度渠道提交
    async upBaiduRp() {
      let { code, data } = await upBaiduRp_api(this.defaultData);
      if (code == 0) {
        this.$message.success(data ? data : "提交成功");
        this.dialogFormVisible = false;
        this.$parent.getRoomInfoById()
      }
    },
    //萝卜渠道提交
    async upLuoboRp() {
      let { code, data } = await upLuoboRp_api(this.defaultData);
      if (code == 0) {
        this.$message.success(data ? data : "提交成功");
        this.dialogFormVisible = false;
        this.$parent.getRoomInfoById()
      }
    },
    //天下房仓渠道提交
    async upFctxRp() {
      let { code, data } = await upFctxRp_api(this.defaultData);
      if (code == 0) {
        this.$message.success(data ? data : "提交成功");
        this.dialogFormVisible = false;
        this.$parent.getRoomInfoById()
      }
    },

  },
  //适用星期
  handleCheckedWeeksChange(val) {
    console.log(val);
  },
};
</script>

<style lang="less" scoped>
.diafooterbtn {
  margin-top: 30px;
  text-align: center;
}

.margintop20 {
  margin-top: 20px;
}

.leftlabelbox {
  text-align: right;
  padding-right: 20px;
  min-height: 28px;
  line-height: 28px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
}

.marginleft10 {
  margin-left: 10px;
}

.height28 {
  height: 28px;
  line-height: 28px;
}
</style>>
