<template>
  <div class="room">
    <div class="roomBoxOne">
      <div>
        <div class="checkOne">
          <span>城市</span>
          <el-input size="mini" v-model="searchData.city"
            style="width: 110px; margin-left: 10px; background-color: #f6f8fb" placeholder="城市名"></el-input>
          <!-- <el-select
            size="mini"
            style="width: 110px; margin-left: 10px; background-color: #f6f8fb"
            v-model="value"
            filterable
            placeholder="城市名"
          >
            <el-option
              style="width: 110px"
              v-for="item in citys"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>-->
          <!-- <VDistpicker
            wrapper="vdistpicker"
            hide-area
            @selected="getCityName"
          ></VDistpicker>-->
        </div>
        <div class="checkOne">
          <el-select size="mini" style="width: 100px" v-model="hotelNameorId">
            <el-option label="酒店名称" value="酒店名称"></el-option>
            <el-option label="酒店ID" value="酒店ID"></el-option>
          </el-select>

          <el-input v-show="hotelNameorId == '酒店名称'" size="mini" style="width: 180px; margin-left: 10px"
            v-model="searchData.hotelName" placeholder="请输入酒店名称"></el-input>
          <el-input v-show="hotelNameorId == '酒店ID'" size="mini" style="width: 180px; margin-left: 10px"
            v-model="searchData.hotelName" placeholder="请输入酒店ID"></el-input>
        </div>
        <div class="checkOne">
          <el-select size="mini" style="width: 100px" v-model="roomNameorId">
            <el-option label="房型名称" value="房型名称"></el-option>
            <el-option label="房型ID" value="房型ID"></el-option>
          </el-select>
          <el-input v-show="roomNameorId == '房型名称'" size="mini" style="width: 180px; margin-left: 10px"
            v-model="searchData.roomName" placeholder="请输入房型名称"></el-input>
          <el-input v-show="roomNameorId == '房型ID'" size="mini" style="width: 180px; margin-left: 10px"
            v-model="searchData.roomName" placeholder="请输入房型ID"></el-input>
        </div>
        <div class="checkOne" style="margin-right: 0px">
          <span>携程售卖状态</span>
          <el-select size="mini" style="width: 100px; margin-left: 10px" v-model="searchData.horseVestId" filterable
            placeholder="请选择">
            <el-option style="width: 100px" v-for="item in ctripVestListForSearch" :key="item.id"
              :label="item.horseName" :value="item.id"></el-option>
          </el-select>
          <el-select size="mini" style="width: 100px; margin-left: 10px" v-model="searchData.CtripStatus" filterable
            placeholder="全部">
            <el-option style="width: 100px" v-for="item in sellStatus" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </div>
        <div class="checkOne">
          <span>艺龙售卖状态</span>
          <el-select size="mini" style="width: 100px; margin-left: 10px" v-model="searchData.SameCityStatus" filterable
            placeholder="全部">
            <el-option style="width: 100px" v-for="item in sellStatus" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </div>
        <div class="checkOne">
          <span>去哪售卖状态</span>
          <el-select size="mini" style="width: 100px; margin-left: 10px" v-model="searchData.QunarStatus" filterable
            placeholder="全部">
            <el-option style="width: 100px" v-for="item in sellStatus" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </div>
        <div class="checkOne">
          <span>美团售卖状态</span>
          <el-select size="mini" style="width: 100px; margin-left: 10px" v-model="searchData.MeiTuanStatus" filterable
            placeholder="全部">
            <el-option style="width: 100px" v-for="item in sellStatus" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </div>
        <div class="checkOne">
          <span>飞猪售卖状态</span>
          <el-select size="mini" style="width: 100px; margin-left: 10px" v-model="searchData.FilPigStatus" filterable
            placeholder="全部">
            <el-option style="width: 100px" v-for="item in sellStatus" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </div>
        <el-button type="primary" @click="searchHotelTableFn" size="mini">搜索</el-button>
        <div class="roomBoxOneOff" @click="setTableSwitch">{{ tableSwitch }}</div>
      </div>
      <div v-if="tableSwitch == '列表收起'" class="roomBoxOneHotelNameBox">
        <!-- @focus="showAllHotelName()" -->

        <div class="roomBoxOneHotelName" :style="{ color: checkedColor == index ? '#256DEF' : '' }"
          @click="searchHotelInfo(item.hotelId, index)" v-for="(item, index) in hotelList" :key="item.hotelId">
          <span style="margin-left: 10px">{{ item.hotelName | formatHotelName() }}</span>
        </div>
        <el-pagination v-if="total > 15" class="roomPagin" background small @current-change="handleCurrentChange"
          :page-size="15" layout="prev, pager, next" :total="total"></el-pagination>
      </div>
    </div>
    <div class="roomBoxTwo">
      <div class="roomBoxTwoHotelName">
        <span v-if="hotelInfoData.cityName">[{{ hotelInfoData.cityName }}]</span>
        {{ hotelInfoData.hotelName }}
      </div>
      <el-button v-show="role_room_log" class="roomRzBtn" size="mini" type="primary" plain
        @click="gotoRoomLog">日志</el-button>
      <el-button class="roomRzBtn" size="mini" type="primary" v-show="role_room_addroomtype" icon="el-icon-plus"
        @click="openAddDia()">新增房型</el-button>
        <el-button class="roomRzBtn" size="mini" type="primary" v-show="role_room_addroomtype" icon="el-icon-plus"
        @click="openElongIdDia()">根据艺龙母酒店id新增</el-button>
        <el-button class="roomRzBtn" size="mini" type="primary" v-show="role_room_addroomtype" icon="el-icon-plus"
        @click="openElongtypeAddDia()">根据艺龙房型新增</el-button>
    </div>
    <div class="roomBoxThree">
      <div class="roomBoxThreeDetail">
        <div class="roomBoxThreeJuxingBox">
          <div class="roomBoxThreeJuxing"></div>
        </div>
        <div class="roomBoxThreeJuxingRight">
          <div class="roomBoxThreeTag firstTag">酒店信息</div>
          <div class="roomBoxThreeTag">
            <div style="display: inline-block; width: 65px; vertical-align: top">酒店地址:</div>
            <div class="roomBoxThreeTagContent" style="width: 270px">{{ hotelInfoData.address }}</div>
          </div>
          <div class="roomBoxThreeTag">
            酒店电话:
            <div class="roomBoxThreeTagContent">{{ hotelInfoData.hotelTel }}</div>
          </div>
          <div class="roomBoxThreeTag">
            酒店负责人:
            <div class="roomBoxThreeTagContent">{{ hotelInfoData.contacts }}</div>
          </div>
          <div class="roomBoxThreeTag">
            负责人电话:
            <div class="roomBoxThreeTagContent">{{ hotelInfoData.contactsTel }}</div>
          </div>
        </div>
      </div>
      <div class="roomBoxThreeDetail roomBoxThreeDetailTwo">
        <div>
          <div class="roomBoxThreeJuxing"></div>
        </div>
      </div>
      <div class="roomBoxThreeDetail">
        <div>
          <div class="roomBoxThreeJuxing"></div>
        </div>
      </div>
    </div>
    <div class="roomBoxFour">
      <el-menu :default-active="activeIndex" class="el-menu-demo" active-text-color="#256DEF" mode="horizontal"
        @select="chooseRoomBoxFourHeadTab">
        <el-menu-item v-for="item in roomBoxFourHeadTabLabels" :key="item.platform" :index="item.platform.toString()">
          {{ item.platformName }}
          <span v-if="activeIndex == item.platform &&
            item.vestList &&
            item.vestList.length > 0
            ">({{ item.vestList[vestIndex].horseVestName }})</span>
          <span v-if="item.status == 1" style="color: #47b267">(在售)</span>
          <span v-if="item.status == 0" style="color: #ff0000">(停售)</span>
        </el-menu-item>
      </el-menu>
      <!-- <el-button
        class="roomBoxFourRightBtn"
        size="mini"
        type="text"
        @click="openEditDiaOne"
        >设置/恢复售卖状态</el-button
      >-->
      <div class="line"></div>
      <div class="roomBoxFourAllMajiaBox">
        <div class="roomBoxFourMajiaBox" v-if="activeIndex == '1'">
          <div class="roomBoxFourMajia" :class="[activeMajiaClass == index ? 'activeMajia' : '']"
            @click="chooseMajia(index, item)" v-for="(item, index) in eLongVestList" :key="item.horseVestId">
            {{ item.horseVestName }}
            <span v-if="item.status == 1" style="color: #47b267">(在售)</span>
            <span v-if="item.status == 0" style="color: #ff0000">(停售)</span>
          </div>
        </div>
        <div class="roomBoxFourMajiaBox" v-if="activeIndex == '2'">
          <div class="roomBoxFourMajia" :class="[activeMajiaClass == index ? 'activeMajia' : '']"
            @click="chooseMajia(index, item)" v-for="(item, index) in fliggyVestList" :key="item.horseVestId">
            {{ item.horseVestName }}
            <span v-if="item.status == 1" style="color: #47b267">(在售)</span>
            <span v-if="item.status == 0" style="color: #ff0000">(停售)</span>
          </div>
        </div>
        <div class="roomBoxFourMajiaBox" v-if="activeIndex == '3'">
          <div class="roomBoxFourMajia" :class="[activeMajiaClass == index ? 'activeMajia' : '']"
            @click="chooseMajia(index, item)" v-for="(item, index) in meiTuanVestList" :key="item.horseVestId">
            {{ item.horseVestName }}
            <span v-if="item.status == 1" style="color: #47b267">(在售)</span>
            <span v-if="item.status == 0" style="color: #ff0000">(停售)</span>
          </div>
        </div>
        <div class="roomBoxFourMajiaBox" v-if="activeIndex == '4'">
          <div class="roomBoxFourMajia" :class="[activeMajiaClass == index ? 'activeMajia' : '']"
            @click="chooseMajia(index, item)" v-for="(item, index) in qunarVestList" :key="item.horseVestId">
            {{ item.horseVestName }}
            <span v-if="item.status == 1" style="color: #47b267">(在售)</span>
            <span v-if="item.status == 0" style="color: #ff0000">(停售)</span>
          </div>
        </div>
        <div class="roomBoxFourMajiaBox" v-if="activeIndex == '5'">
          <div class="roomBoxFourMajia" :class="[activeMajiaClass == index ? 'activeMajia' : '']"
            @click="chooseMajia(index, item)" v-for="(item, index) in ctripVestList" :key="item.horseVestId">
            {{ item.horseVestName }}
            <span v-if="item.status == 1" style="color: #47b267">(在售)</span>
            <span v-if="item.status == 0" style="color: #ff0000">(停售)</span>
          </div>
        </div>
        <div class="roomBoxFourMajiaBox" v-if="activeIndex == '6'">
          <div class="roomBoxFourMajia" :class="[activeMajiaClass == index ? 'activeMajia' : '']"
            @click="chooseMajia(index, item)" v-for="(item, index) in baiduVestList" :key="item.horseVestId">
            {{ item.horseVestName }}
            <span v-if="item.status == 1" style="color: #47b267">(在售)</span>
            <span v-if="item.status == 0" style="color: #ff0000">(停售)</span>
          </div>
        </div>
        <div class="roomBoxFourMajiaBox" v-if="activeIndex == '7'">
          <div class="roomBoxFourMajia" :class="[activeMajiaClass == index ? 'activeMajia' : '']"
            @click="chooseMajia(index, item)" v-for="(item, index) in luoboVestList" :key="item.horseVestId">
            {{ item.horseVestName }}
            <span v-if="item.status == 1" style="color: #47b267">(在售)</span>
            <span v-if="item.status == 0" style="color: #ff0000">(停售)</span>
          </div>
        </div>
        <div class="roomBoxFourMajiaBox" v-if="activeIndex == '8'">
          <div class="roomBoxFourMajia" :class="[activeMajiaClass == index ? 'activeMajia' : '']"
            @click="chooseMajia(index, item)" v-for="(item, index) in fctxVestList" :key="item.horseVestId">
            {{ item.horseVestName }}
            <span v-if="item.status == 1" style="color: #47b267">(在售)</span>
            <span v-if="item.status == 0" style="color: #ff0000">(停售)</span>
          </div>
        </div>
      </div>
      <div style="margin-top: 20px; position: relative; margin-bottom: 10px">
        <el-button v-show="role_room_editroomtwo" size="mini" type="primary" @click="openEditDiaTwo">批量改房态</el-button>
        <el-button v-show="role_room_editprice" size="mini" type="primary"
          @click="openEditDiaForRoomPrice">批量改价</el-button>
        <el-button v-show="role_room_editroomstype" size="mini" type="primary"
          @click="openEditDiaForRoomsType">批量设置售卖房型</el-button>
        <el-button v-show="role_room_editinterceptrule" size="mini" type="primary"
          @click="openEditInterceptRule(1)">二次拦截</el-button>
        <el-button v-show="role_room_editinterceptrule" size="mini" type="primary"
          @click="openEditInterceptRule(2)">取消二次拦截</el-button>
        <el-button v-if="searchRoomData.platform == 1" plain size="mini" type="primary"
          @click="set30MinuteCancelFn(1)">设置30分钟取消</el-button>
        <el-button v-if="searchRoomData.platform == 1" plain size="mini" type="primary"
          @click="set30MinuteCancelFn(0)">取消30分钟取消</el-button>
          <el-button v-show="role_room_editprice" size="mini" type="primary"
          @click="openSynchronization">一键同步</el-button>
        <div class="roomBoxFourTimeBox">
          <div class="roomBoxFourTimePreBox">
            <i @click="getPredata" class="el-icon-arrow-left"></i>
          </div>
          <el-date-picker size="mini" style="width: 130px; margin-left: 10px; margin-right: 10px"
            v-model="searchRoomData.chooseDate" type="date" @change="changeDate" format="yyyy-MM-dd"
            value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
          <div class="roomBoxFourTimePreBox">
            <i @click="getNestdata" class="el-icon-arrow-right"></i>
          </div>
        </div>
        <div class="roomBoxFourZhou">
          <el-select style="width: 71px" v-model="choose_week" size="mini" @change="changeWeekorMonth">
            <el-option value="按周" label="按周"></el-option>
            <el-option value="按月" label="按月"></el-option>
          </el-select>
        </div>
      </div>
      <!-- 开始三级连选，外层一级选择 -->
      <div class="roomBoxFourWeeksBox">
        <el-checkbox style="width: 20%; height: 60px; line-height: 60px" :indeterminate="indeterminate"
          v-model="ischeckAll" @change="handleCheckAllChange">房型</el-checkbox>
        <div class="roomBoxFourWeek">
          <div>
            <el-checkbox-group v-model="checkList">
              <el-checkbox style="width: 14%; margin-right: 0; text-align: center" v-for="(item, index) in weeks"
                :key="index">{{ item }}</el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
      </div>
      <!-- 二级选择联动 -->
      <div v-for="(item, topIndex) in roomBigList" :key="item.firstId">
        <div class="roomBoxFourFangTitle">
          <el-checkbox :indeterminate="item.indeterminate" v-model="item.selected"
            @change="handleCheckTwoChange(topIndex, item.firstId, $event)"></el-checkbox>
          <div style="display: inline-block; width: 15%; margin-left: 5px">
            <span>{{ item.roomTypeName | formatRoomTypeName }}</span>
            <span v-if="item.isMatching == 0" style="color: #ee4d52">未匹配</span>
            <span v-if="item.isMatching == 2" style="color: #ee4d52">匹配中</span>
          </div>

          <div class="roomBoxFourFangTitleImgBox">
            <!-- <img src="../../assets/imgs/房间数.png" alt="" />
            <span>{{ item.roomQuantity }}间</span>-->

            <img src="../../assets/imgs/房间面积.png" alt />
            <span>{{ item.areaRange }}m</span>

            <img src="../../assets/imgs/房间楼层.png" alt />
            <el-tooltip v-if="item.floorRange && item.floorRange.length > 10" :content="item.floorRange"
              placement="bottom" effect="light">
              <span style="cursor: pointer">
                {{
            item.floorRange | formartFloorRange
          }}
              </span>
            </el-tooltip>
            <span v-else>{{ item.floorRange }}</span>

            <img src="../../assets/imgs/房间人数.png" alt />
            <span>{{ item.maxOccupancy }}人</span>

            <img src="../../assets/imgs/房间床型.png" alt />
            <span>{{ item.bedType }}</span>

            <!-- <img src="../../assets/imgs/房间加床.png" alt="" />
            <span v-if="item.extraBedFee">{{ item.extraBedFee }}</span>
            <span v-else>不可加床</span>-->

            <img src="../../assets/imgs/房间宽带.png" alt />
            <span v-if="item.wiredBroadnet == 0">没有</span>
            <span v-else-if="item.wiredBroadnet == 1">全部房间有且收费</span>
            <span v-else-if="item.wiredBroadnet == 2">全部房间有且免费</span>
            <span v-else-if="item.wiredBroadnet == 3">部分房间有且收费</span>
            <span v-else-if="item.wiredBroadnet == 4">部分房间有且免费</span>

            <img src="../../assets/imgs/房间wifi.png" alt />
            <span v-if="item.wirelessBroadnet == 0">没有</span>
            <span v-else-if="item.wirelessBroadnet == 1">全部房间有且收费</span>
            <span v-else-if="item.wirelessBroadnet == 2">全部房间有且免费</span>
            <span v-else-if="item.wirelessBroadnet == 3">部分房间有且收费</span>
            <span v-else-if="item.wirelessBroadnet == 4">部分房间有且免费</span>

            <img src="../../assets/imgs/房间禁烟.png" alt />
            <span v-if="item.isAllowSmoking == '-100'"></span>
            <span v-else-if="item.isAllowSmoking == 1">可吸烟</span>
            <span v-else-if="item.isAllowSmoking == 2">禁烟</span>
          </div>

          <div class="roomBoxFourTitleIcon">
            <el-tooltip content="修改物理房型名称" placement="bottom" effect="light">
              <i @click="openEditRoomTypeName(item.roomTypeId)" style="margin-right: 10px; cursor: pointer"
                class="el-icon-edit"></i>
            </el-tooltip>
            <el-tooltip content="重新发布物理房型" placement="bottom" effect="light">
              <i @click="openReSellRoomType(item.roomTypeId)" style="margin-right: 10px; cursor: pointer"
                class="el-icon-connection"></i>
            </el-tooltip>
            <el-tooltip content="重新发布售卖房型" placement="bottom" effect="light">
              <i @click="openReSellRoom(item.roomTypeId)" style="margin-right: 10px; cursor: pointer"
                class="el-icon-position"></i>
            </el-tooltip>
            <el-tooltip content="匹配" placement="bottom" effect="light">
              <i @click="openMatchingRoom(item)" style="margin-right: 10px; cursor: pointer"
                class="el-icon-bangzhu"></i>
            </el-tooltip>
            <el-tooltip content="删除" placement="bottom" effect="light">
              <i @click="openDelSellRoom(item.roomTypeId)" style="margin-right: 10px; cursor: pointer"
                class="el-icon-delete"></i>
            </el-tooltip>
            <el-tooltip content="新增售卖房型" placement="bottom" effect="light">
              <i @click="openAddSellRoom(item)" style="margin-right: 10px; cursor: pointer" class="el-icon-plus"></i>
            </el-tooltip>
            <el-tooltip :content="item.tooltipInfo" placement="bottom" effect="light">
              <i @click="swithHoteInfo(item)" style="margin-right: 10px; cursor: pointer" :class="item.elIconArrow"></i>
            </el-tooltip>
          </div>
        </div>
        <!-- 三级联动选择 -->
        <div v-if="item.elIconArrow == 'el-icon-arrow-down'">
          <div v-for="ctem in item.hotelRoomVestVo" :key="ctem.secondId" style="border-bottom: 1px solid #dfe3ec">
            <el-checkbox style="margin-left: 10px" v-model="ctem.selected" @change="
            handleCheckThreeChange(
              topIndex,
              ctem.secondId,
              item.firstId,
              $event
            )
            "></el-checkbox>

            <div slot="reference" style="
                width: 220px;
                display: inline-block;
                vertical-align: middle;
                padding-left: 5px;
                padding-top: 10px;
              ">
              <div style="display: inline-block; margin-bottom: 5px">
                {{ ctem.roomName }}
                {{ ctem.mealType }}
              </div>

              <!-- <span v-if="ctem.mealType==0">无早</span>
            <span v-else-if="ctem.mealType==1">晚餐</span>
            <span v-else-if="ctem.mealType==2">午餐</span>
            <span v-else-if="ctem.mealType==3">午+晚餐</span>
            <span v-else-if="ctem.mealType==4">早餐</span>
            <span v-else-if="ctem.mealType==5">早+晚餐</span>
            <span v-else-if="ctem.mealType==6">早+午餐</span>
              <span v-else-if="ctem.mealType==7">早+午+晚餐</span>-->
             
              <img @click="openEditDiaForRoomsTypeTwo(ctem)" src="../../assets/imgs/修改.png" style="
                  margin-bottom: 5px;
                  position: relative;
                  top: 6px;
                  width: 12px;
                  height: 12px;
                  cursor: pointer;
                " alt />
                 <i class="el-icon-edit" @click="openEditRoomNameDia(ctem)" style="color: #409EFF;font-size: 14px;margin: 0px 5px; cursor: pointer;" ></i>
              <el-tooltip v-if="ctem.isPromotion == 1" :content="ctem.promotionInfo" placement="top" effect="light">
                <div class="cuxiaobox">促</div>
              </el-tooltip>
              <el-tooltip v-if="ctem.promotion && ctem.promotion.length > 0" :content="ctem.promotion.join(',')"
                placement="top" effect="light">
                <div class="cuxiaobox">礼</div>
              </el-tooltip>
              <!-- <div
                style="
                  border: 1px solid #ff6600;
                  color: #ff6600;
                  display: inline-block;
                "
              >
                散客价
              </div>
              <div
                style="
                  border: 1px solid #256def;
                  color: #256def;
                  display: inline-block;
                  margin-bottom: 5px;
                "
              >
                共享房价
              </div>-->
              <div v-if="ctem.isMatching == 0" style="
                  margin-bottom: 5px;
                  display: inline-block;
                  color: #ee4d52;
                ">未匹配</div>
              <div v-if="ctem.isMatching == 3" style="
                  margin-bottom: 5px;
                  display: inline-block;
                  color: #ee4d52;
                ">匹配中</div>
              <div v-if="ctem.isMatching == 2" style="
                  margin-bottom: 5px;
                  display: inline-block;
                  color: #ee4d52;
                ">匹配失败</div>
              <!-- <div style="margin-bottom: 5px; color: #999999">
                  房型ID:{{ ctem.roomVestId }}
              </div>-->
              <!-- <div style="margin-bottom: 5px; color: #999999">
                  {{ platformName }}ID:{{ ctem.matchingHotelId }}
              </div>-->
              <div style="margin-bottom: 5px; color: #999999">取消规则:{{ ctem.cancelRule }}</div>
              <div v-if="ctem.scheduledRule" style="margin-bottom: 5px; color: #999999">预定规则:{{ ctem.scheduledRule }}
              </div>
            </div>

            <div style="
                width: 812px;
                min-height: 100px;
                display: inline-block;
                vertical-align: top;
              ">
              <!-- 四级联动 -->
              <div class="roomBoxFourFangContent" :class="[etem.roomStatus == 0 ? 'guanfangbg' : 'kaifangbg']"
                v-for="etem in ctem.roomVestpriceJson" :key="etem.id"
                @click="openEditRoomType(ctem, etem.effectiveDate, $event)">
                <!-- <div style="margin-top:5px;">
                双早 09-15
                <div class="roomBoxFourFangContentSwitch">
                   <el-switch
                  
                  size='mini'
                  v-model="etem.isCheck"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                >
                </el-switch>

                </div>
               
              </div>
              <div style="color: #ff6600; font-weight: bold;margin-top:5px;">
                {{ etem.price }}
                <div class="roomBoxFourFangContentliwu">
                      <i style="font-size: 18px" class="el-icon-present"></i>
                </div>
                
              </div>
              <div style="color: #ff6600;margin-top:5px;">
                <div class="roomBoxFourFangContentTimer">
                  <i style="font-size: 18px" class="el-icon-timer"></i
                >

                </div>
                {{ etem.addPrice }}
              </div>
              <div style="margin-top:5px;">2/2/(0)</div>
               
              <div style="margin-top:5px;">
                <i
                  style="font-size: 18px; color: #ff6600"
                
                  class="el-icon-wind-power"
                ></i
                >2/2
                <div class="roomBoxFourFangContentqu">
                  取
                </div>
                </div>-->
                <div class="roomBoxFourFangContentThreeBox">
                  <div style="height: 40px; margin-top: 5px">
                    <span>{{ ctem.mealType }}</span>
                    <!-- <span v-if="etem.mealType == 0">无早</span>
                    <span v-else-if="etem.mealType == 1">晚餐</span>
                    <span v-else-if="etem.mealType == 2">午餐</span>
                    <span v-else-if="etem.mealType == 3">午+晚餐</span>
                    <span v-else-if="etem.mealType == 4">早餐</span>
                    <span v-else-if="etem.mealType == 5">早+晚餐</span>
                    <span v-else-if="etem.mealType == 6">早+午餐</span>
                    <span v-else-if="etem.mealType == 7">早+午+晚餐</span>-->
                  </div>
                  <div style="height: 30px">
                    <!-- <i
                    style="font-size: 18px; color: #ff6600"
                    class="el-icon-timer"
                    ></i>-->
                    <img v-show="etem.immediateTime == 1" src="../../assets/imgs/倒计时.png" alt />
                  </div>
                  <div style="height: 20px">
                    <!-- <i
                    style="font-size: 18px; color: #ff6600"
                    class="el-icon-wind-power"
                    ></i>-->
                    <img v-show="etem.isInActivity == 1" src="../../assets/imgs/活动.png" alt />
                  </div>
                </div>
                <div class="roomBoxFourFangContentThreeBox">
                  <div style="margin-top: 5px; cursor: pointer">{{ etem.effectiveDateNew }}</div>
                  <div class="roomBoxFourFangContentThreeBoxPrice">
                    <span v-if="etem.purchasePrice">￥ {{ etem.purchasePrice }}</span>
                    <span v-else style="font-weight: bold; color: #000000">暂无价格</span>
                  </div>

                  <div class="roomBoxFourFangContentThreeBoxPrice"
                    style="color: #ff6600; margin-top: 5px; height: 20px">
                    <span v-if="etem.profit">+￥</span>
                    {{ etem.profit }}
                  </div>
                  <div style="margin-top: 5px; width: 150%; color: #666666">
                    <span v-if="etem.bookingLimit">{{ etem.bookingLimit }}</span>
                    <span v-else>0</span>
                    /
                    <span v-if="etem.setRoomAllotment">{{ etem.setRoomAllotment }}</span>
                    <span v-else>0</span>
                    ({{ etem.soldRoomAllotment }})
                  </div>
                  <!-- <div style="margin-top: 5px">2/2</div> -->
                </div>
                <div class="roomBoxFourFangContentThreeBox" style="text-align: right">
                  <div style="height: 30px" @click.stop>
                    <el-switch v-if="etem.roomStatus == 2" size="mini" @change="changgeSwitch(etem, ctem, $event)"
                      v-model="etem.roomStatus" active-color="#13ce66" inactive-color="#ff4949" active-value="2"
                      inactive-value="0"></el-switch>
                    <el-switch v-else size="mini" @change="changgeSwitch(etem, ctem, $event)" v-model="etem.roomStatus"
                      active-color="#13ce66" inactive-color="#ff4949" active-value="1" inactive-value="0"></el-switch>
                  </div>
                  <div style="height: 47px">
                    <!-- <i
                    style="font-size: 18px; color: #ff6600; font-weight: bold"
                    class="el-icon-present"
                    ></i>-->
                    <img v-show="etem.isHavaGiftBag == 1" src="../../assets/imgs/礼盒.png" alt />
                  </div>
                  <div style="height: 20px">
                    <div v-if="etem.isCanCancel == 1" class="roomBoxFourFangContentqu">
                      <el-tooltip :content="etem.canCleString" placement="bottom" effect="light">
                        <span>取</span>
                      </el-tooltip>
                    </div>
                    <div v-if="etem.isIntercept == 1" class="roomBoxFourFangContentqu"
                      style="color: #ff0000; border-color: #ff0000">拦</div>
                      <div v-if="etem.roomStatus == 2" class="roomBoxFourFangContentqu">
                      <el-tooltip content="限量售卖" placement="bottom" effect="light">
                        <span>限</span>
                      </el-tooltip>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div style="width: 135px; display: inline-block; vertical-align: top">
              <!-- text-align: center; -->
              <div class="inlineBox50" style="vertical-align: top">
                <el-button @click="openEditRoomType(ctem)" size="small" type="text">修改</el-button>
              </div>
              <div class="inlineBox50" v-if="ctem.active == 1 && activeIndex != 5">
                <el-button size="small" @click="delRoom(ctem.roomVestId, 0)" type="text">下线</el-button>
              </div>
              <div class="inlineBox50" v-if="ctem.active == 0 && activeIndex != 5">
                <el-button size="small" @click="delRoom(ctem.roomVestId, 1)" type="text">上线</el-button>
              </div>

              <div class="inlineBox50">
                <el-button
                  size="small"
                  type="text"
                  @click="realDelRoom(ctem.roomVestId)"
                >
                  删除</el-button
                >
              </div>
              <div class="inlineBox50">
                <el-button size="small" type="text" @click="delAssociatedRoom(ctem.roomVestId)">关联删除</el-button>
              </div>
              <div class="inlineBox50" v-if="ctem.isMatching == 3">
                <el-button size="small" @click="
            checkRoomVestMappingFn(
              item.hotelSonVestId,
              item.roomTypeVestId,
              ctem.roomVestId
            )
            " type="text">查看匹配</el-button>
              </div>
              <div class="inlineBox50" v-if="ctem.isMatching == 0">
                <el-button size="small" @click="sendRoomInfoFn(ctem)" type="text">推送</el-button>
              </div>
              <div class="inlineBox50" v-if="ctem.isMatching == 2">
                <el-button size="small" @click="sendRoomInfoFn(ctem)" type="text">重新推送</el-button>
              </div>
              <div class="inlineBox50" v-if="ctem.isMatching == 1">
                <el-button size="small" @click="changeIsMatching(ctem.roomVestId)" type="text">修改匹配</el-button>
              </div>
              <div class="inlineBox50" v-if="searchRoomData.platform == 4">
                <el-button size="small" @click="openLibao(ctem.roomVestId)" type="text">礼包</el-button>
              </div>
              <div class="inlineBox50" v-if="searchRoomData.platform == 7">
                <el-button size="small" @click="openLibaoLuobo(ctem.roomVestId)" type="text">礼包</el-button>
              </div>
              <div class="inlineBox50" v-if="searchRoomData.platform == 1">
                <!-- <el-button size="small" @click="yilongPush(ctem.roomVestId)" type="text">推送匹配</el-button> -->
                <el-button size="small" @click="yilongPushFn(ctem.roomVestId)" type="text">推送匹配</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 新增物理房型 -->
    <AddorUpRoomType ref="addroom"></AddorUpRoomType>
    <!-- 根据艺龙房型新增物理房型 -->
    <AddorUpRoomElongType ref="elongtypeaddroom"></AddorUpRoomElongType>
 
    <Addelongiddia ref="elongiddia"></Addelongiddia>

    <!-- 设置/恢复售卖状态 -->
    <EditRoomOne ref="editroomone"></EditRoomOne>
    <!-- 批量改房态 -->
    <EditRoomTwo ref="editroomtwo"></EditRoomTwo>
    <!-- 批量改房价 -->
    <EditRoomPrice ref="editroomprice"></EditRoomPrice>
    <!-- 批量设置售卖房型 -->
    <EditRoomsType ref="editroomstype"></EditRoomsType>

    <!-- 二次拦截 -->
    <EditInterceptRule ref="editinterceptrule"></EditInterceptRule>
    <!-- 修改售卖房型（除了携程的其他渠道） -->
    <EditRoomType ref="editroomtype"></EditRoomType>
    <!-- 携程渠道 -->
    <EditRoomTypeForCtrip ref="editroomtypeforctrip"></EditRoomTypeForCtrip>
    <!-- 新增售卖房型 -->
    <AddSellRoom ref="addsellroom"></AddSellRoom>
    <!-- 匹配（除了去哪儿的其他渠道 -->
    <MatchingRoom ref="matchingroom"></MatchingRoom>
    <!-- 去哪儿渠道的匹配 -->
    <MatchingRoomForQunar ref="matchingroomqunar"></MatchingRoomForQunar>
    <!-- 重新发布物理房型 -->
    <ReRoomTypeDia ref="readdroomtype"></ReRoomTypeDia>
    <!-- 重新发布售卖房型 -->
    <ReSellRoomDia ref="readdroom"></ReSellRoomDia>
    <!-- 去哪儿礼包 -->
    <GiftbagDia ref="giftbag"></GiftbagDia>
    <!-- 萝卜礼包 -->
    <GiftbagluoboDia ref="giftbagluobo"></GiftbagluoboDia>
    <!-- 修改物理房型名称 -->
    <Editroomtypename ref="editroomtypename"></Editroomtypename>
    <!-- 一键同步 -->
    <Synchronizationdia ref="synchronizationdia"></Synchronizationdia>

    <!-- 修改房间名称 -->
    <Editroomnamedia ref="editroomnamedia"></Editroomnamedia>
  </div>
</template>

<script>
import VDistpicker from "v-distpicker";
import AddorUpRoomType from "../../components/dialog/room/AddorUpRoomType.vue";
import AddorUpRoomElongType from "../../components/dialog/room/AddorUpRoomElongType.vue";
import EditRoomOne from "../../components/dialog/room/EditRoomOne.vue";
import EditRoomTwo from "../../components/dialog/room/EditRoomTwo.vue";
import EditRoomPrice from "../../components/dialog/room/EditRoomPrice.vue";
import EditRoomsType from "../../components/dialog/room/EditRoomsType.vue";
import EditInterceptRule from "../../components/dialog/room/EditInterceptRule.vue";
import EditRoomType from "../../components/dialog/room/EditRoomType.vue";
import EditRoomTypeForCtrip from "../../components/dialog/room/EditRoomTypeForCtrip.vue";
import AddSellRoom from "../../components/dialog/room/AddSellRoom.vue";
import MatchingRoom from "../../components/dialog/room/MatchingRoom.vue";
import MatchingRoomForQunar from "../../components/dialog/room/MatchingRoomForQunar.vue";
import ReRoomTypeDia from "../../components/dialog/room/ReRoomTypeDia.vue";
import ReSellRoomDia from "../../components/dialog/room/ReSellRoomDia.vue";
import GiftbagDia from "../../components/dialog/room/GiftbagDia.vue";
import GiftbagluoboDia from "../../components/dialog/room/GiftbagluoboDia.vue";
import Editroomtypename from "../../components/dialog/room/Editroomtypename.vue";
import Addelongiddia from "../../components/dialog/room/Addelongiddia.vue";
import Synchronizationdia from "../../components/dialog/room/Synchronizationdia.vue";
import Editroomnamedia from "../../components/dialog/room/Editroomnamedia.vue";



import {
  getCtripVest_api,
  delSellRoom_api,
  hotelList_api,
  hotelInfo_api,
  platformVo_api,
  roomInfoById_api,
  sendRoomInfo_api,
  checkRoomVestMapping_api,
  upYiLongRp_api,
  upFlyPigRp_api,
  upMeiTuanRp_api,
  upQunarRp_api,
  upDltRp_api,
  upBaiduRp_api,
  upLuoboRp_api,
  upFctxRp_api,
  delRoom_api,
  realDelRoom_api,
  delAssociatedRoom_api,
  changeIsMatching_api,
  set30MinuteCancel_api,
  yilongPush_api,
} from "../../apis/room";
import { getRoleKey } from "@/utils/rolekey";
import local from "@/utils/local.js";
import req from "@/utils/req";
export default {
  components: {
    VDistpicker,
    AddorUpRoomType,
    AddorUpRoomElongType,
    EditRoomOne,
    EditRoomTwo,
    EditRoomPrice,
    EditRoomsType,
    EditInterceptRule,
    EditRoomType,
    EditRoomTypeForCtrip,
    AddSellRoom,
    MatchingRoom,
    MatchingRoomForQunar,
    ReRoomTypeDia,
    ReSellRoomDia,
    GiftbagDia,
    Editroomtypename,
    Addelongiddia,
    Synchronizationdia,
    Editroomnamedia,
    GiftbagluoboDia,

  },
  data() {
    return {
      role_room_addroomtype: getRoleKey("role_room_addroomtype"),
      role_room_log: getRoleKey("role_room_log"),
      role_room_editroomtwo: getRoleKey("role_room_editroomtwo"),
      role_room_editprice: getRoleKey("role_room_editprice"),
      role_room_editroomstype: getRoleKey("role_room_editroomstype"),
      role_room_editinterceptrule: getRoleKey("role_room_editinterceptrule"),

      eLongVestList: [],
      fliggyVestList: [],
      meiTuanVestList: [],
      qunarVestList: [],
      ctripVestList: [],
      baiduVestList: [],
      luoboVestList: [],
      fctxVestList: [],
      
      vestIndex: 0,
      hotelInfoData: "",
      hotelNameorId: "酒店名称",
      roomNameorId: "房型ID",
      choose_week: "按周",
      platformName: "",
      //查询room参数
      searchRoomData: {
        hotelSonId: "",
        platform: "",
        hotelVestId: "",
        chooseWeek: "按周",
        chooseDate: this.initDate(new Date()),
      },
      citys: [],

      ctripVestListForSearch: [],
      total: 0,
      tableSwitch: "列表收起",
      searchData: {
        city: "",
        hotelName: "",
        hotelId: "",
        roomName: "",
        roomId: "",
        horseVestId: "",
        SameCityStatus: "",
        CtripStatus: "",
        QunarStatus: "",
        MeiTuanStatus: "",
        FilPigStatus: "",
        page: 1,
        rows: "15",
      },
      sellStatus: [
        { label: "全部", value: "" },
        { label: "在售", value: "1" },
        { label: "停售", value: "0" },
        // { label: "下线", value: "3" },
        // { label: "限时售卖", value: "4" },
      ],

      value: "",
      hotelList: [],
      currentPage: 1,

      activeRoomBoxFourHeadTab: "1",
      roomBoxFourHeadTabLabels: [
        { platform: "1", platformName: "艺龙" },
        { platform: "2", platformName: "飞猪" },
        { platform: "3", platformName: "美团" },
        { platform: "4", platformName: "去哪" },
        { platform: "5", platformName: "携程" },
        { platform: "6", platformName: "百度" },
        { platform: "7", platformName: "萝卜" },
        { platform: "8", platformName: "天下房仓" },
      ],
      activeIndex: "1",

      activeMajiaClass: 0,
      xiechengMajiaTitle: "",
      timeDate: new Date(),
      zhouOryue: "1",
      weeks: [
        // { week: "周一", id: 1 },
        // { week: "周二", id: 2 },
        // { week: "周三", id: 3 },
        // { week: "周四", id: 4 },
        // { week: "周五", id: 5 },
        // { week: "周六", id: 6 },
        // { week: "周日", id: 7 },
      ],
      isIndeterminate: true,
      indeterminate: false,
      ischeckAll: false,
      checkList: "",
      checkedColor: 0,
      roomBigList: [],
      isSwitchApi: false,
    };
  },
  created() {
    this.getCtripVestList();
    // console.log(this.$route.query.id);
    if (this.$route.query.id) {
      this.searchData.roomName = this.$route.query.id;
      this.searchHotelTable();
    } else if (this.$route.query.hotelId) {
      this.searchData.hotelName = this.$route.query.hotelId;
      this.searchHotelTable();
    } else {
      this.searchHotelTable();
    }
  },
  mounted() { },
  filters: {
    formatHotelName(val) {
      if (val.length < 20) {
        return val;
      } else {
        let newstr = val.substring(0, 16);
        return newstr + "...";
      }
    },
    formatRoomTypeName(val) {
      if (val.length < 12) {
        return val;
      } else {
        let newstr = val.substring(0, 9);
        return newstr + "...";
      }
    },
    formartFloorRange(val) {
      if (val.length < 10) {
        return val;
      } else {
        let newstr = val.substring(0, 7);
        return newstr + "...";
      }
    },
  },
  methods: {
    searchHotelTableFn() {
      local.remove("roomPageHotelId");
      this.searchData.page = 1;
      this.searchHotelTable();
    },
    //获取城市名字
    getCityName(data) {
      this.searchData.city = data.city.value;
      //  console.log(data.city.value);
    },
    //渠道选择
    chooseRoomBoxFourHeadTab(index) {
      this.activeIndex = index;
      this.searchRoomData.platform = index;
      this.vestIndex = 0;
      this.activeMajiaClass = 0;
      if (index == 1) {
        if (this.eLongVestList.length > 0) {
          this.searchRoomData.hotelVestId = this.eLongVestList[0].horseVestId;
          this.platformName = "艺龙";
          this.getRoomInfoById();
        } else {
          this.roomBigList = [];
        }
      } else if (index == 2) {
        if (this.fliggyVestList.length > 0) {
          this.searchRoomData.hotelVestId = this.fliggyVestList[0].horseVestId;
          this.platformName = "飞猪";
          this.getRoomInfoById();
        } else {
          this.roomBigList = [];
        }
      } else if (index == 3) {
        if (this.meiTuanVestList.length > 0) {
          this.searchRoomData.hotelVestId = this.meiTuanVestList[0].horseVestId;
          this.platformName = "美团";
          this.getRoomInfoById();
        } else {
          this.roomBigList = [];
        }
      } else if (index == 4) {
        if (this.qunarVestList.length > 0) {
          this.searchRoomData.hotelVestId = this.qunarVestList[0].horseVestId;
          this.platformName = "去哪";
          this.getRoomInfoById();
        } else {
          this.roomBigList = [];
        }
      } else if (index == 5) {
        if (this.ctripVestList.length > 0) {
          this.searchRoomData.hotelVestId = this.ctripVestList[0].horseVestId;
          this.platformName = "携程";
          this.getRoomInfoById();
        } else {
          this.roomBigList = [];
        }
      } else if (index == 6) {
        if (this.baiduVestList.length > 0) {
          this.searchRoomData.hotelVestId = this.baiduVestList[0].horseVestId;
          this.platformName = "百度";
          this.getRoomInfoById();
        } else {
          this.roomBigList = [];
        }
      } else if (index == 7) {
        if (this.luoboVestList.length > 0) {
          this.searchRoomData.hotelVestId = this.luoboVestList[0].horseVestId;
          this.platformName = "萝卜";
          this.getRoomInfoById();
        } else {
          this.roomBigList = [];
        }
      } else if (index == 8) {
        if (this.fctxVestList.length > 0) {
          this.searchRoomData.hotelVestId = this.fctxVestList[0].horseVestId;
          this.platformName = "天下房仓";
          this.getRoomInfoById();
        } else {
          this.roomBigList = [];
        }
      }
    },
    
    //选择马甲
    chooseMajia(index, item) {
      this.activeMajiaClass = index;
      this.vestIndex = index;
      this.xiechengMajiaTitle = item.horseVestName;
      this.searchRoomData.hotelVestId = item.horseVestId;
      this.getRoomInfoById();
    },
    //根据酒店id，马甲id，平台id查询房态管理
    async getRoomInfoById() {
      if (this.choose_week == "按周") {
        this.searchRoomData.chooseWeek = 7;
      } else {
        this.searchRoomData.chooseWeek = 30;
      }

      if (this.isSwitchApi == true) {
        req.changeShowLoadingF();
      }
      let { code, data } = await roomInfoById_api(this.searchRoomData);
      if (code == 0) {
        this.isSwitchApi = false;
        req.changeShowLoadingT();
        this.roomBigList = [];
        //给数据加上indeterminate，selected做四级联选用
        let idCount = 1;
        let firstId = 1;
        let secondId = 1;
        // let thirdId = 1;
        if (
          data.hotelRoomTypeVestList != null &&
          data.hotelRoomTypeVestList.length > 0
        ) {
          data.hotelRoomTypeVestList.forEach((item) => {
            item.selected = false;
            item.indeterminate = false;
            item.elIconArrow = "el-icon-arrow-down";
            item.tooltipInfo = "收起";
            item.firstId = firstId;
            firstId++;
            if (
              item.hotelRoomVestVo != null &&
              item.hotelRoomVestVo.length > 0
            ) {
              item.hotelRoomVestVo.forEach((ctem) => {
                ctem.selected = false;
                ctem.secondId = secondId;
                secondId++;
                if (
                  ctem.roomVestpriceJson != null &&
                  ctem.roomVestpriceJson.length > 0
                ) {
                  ctem.roomVestpriceJson.forEach((etem) => {
                    etem.id = idCount;
                    idCount++;
                  });
                }
              });
            }
          });
        }

        this.roomBigList = data.hotelRoomTypeVestList;
        this.weeks = data.week;
        //console.log(this.roomBigList);
      } else {
        this.roomBigList = [];
      }
    },

    //列表开关
    setTableSwitch() {
      if (this.tableSwitch == "列表收起") {
        this.tableSwitch = "列表展开";
      } else {
        this.tableSwitch = "列表收起";
      }
    },
    //获取携程马甲
    async getCtripVestList() {
      let { code, data } = await getCtripVest_api({});
      if (code == 0) {
        this.ctripVestListForSearch = data;
        //   console.log(this.ctripVestListForSearch, "马甲");
      }
    },
    //根据条件查询酒店列表
    async searchHotelTable() {
      this.currentPage = 1;

      let { code, data } = await hotelList_api(this.searchData);
      if (code == 0) {
        this.hotelList = data.records;
        this.total = data.total;
        // console.log(this.hotelList, "酒店列表");
        // if (this.hotelList.length > 0 ) {
        //   // this.getHotelInfo(this.hotelList[0].hotelId, 0);
        //   if(!local.get("roomPageHotelId")){
        //        this.searchHotelInfo(this.hotelList[0].hotelId, 0);
        //   }
        // }
        if (local.get("roomPageHotelId")) {
          this.getHotelInfo(local.get("roomPageHotelId"));
        } else {
          if (this.hotelList.length > 0) {
            this.searchHotelInfo(this.hotelList[0].hotelId, 0);
          }
        }
      }
    },
    //酒店列表分页
    handleCurrentChange(val) {
      // console.log(val);
      this.searchData.page = val;
      this.searchHotelTable();
    },
    searchHotelInfo(id, index) {
      local.set("roomPageHotelId", id);
      this.checkedColor = index;
      this.getHotelInfo(id);
    },
    searchHotelInfo2(id, index) {
      this.checkedColor = index;
      this.getHotelInfo(id);
    },
    //根据酒店ID查询酒店信息
    async getHotelInfo(id) {
      // this.checkedColor = index;
      this.searchRoomData.hotelSonId = id;
      let { code, data } = await hotelInfo_api({
        hotelSonId: id,
      });
      if (code == 0) {
        this.hotelInfoData = data;
        this.getPlatformVo(id);
      }
    },
    //根据酒店id查询酒店所有渠道马甲
    async getPlatformVo(id) {
      let { code, data } = await platformVo_api({ hotelSonId: id });
      if (code == 0) {
        this.roomBoxFourHeadTabLabels = data;
        data.forEach((item) => {
          if (item.platform == "1") {
            this.eLongVestList = item.vestList;
          }
          if (item.platform == "2") {
            this.fliggyVestList = item.vestList;
          }
          if (item.platform == "3") {
            this.meiTuanVestList = item.vestList;
          }
          if (item.platform == "4") {
            this.qunarVestList = item.vestList;
          }
          if (item.platform == "5") {
            this.ctripVestList = item.vestList;
          }
          if (item.platform == "6") {
            this.baiduVestList = item.vestList;
          }
          if (item.platform == "7") {
            this.luoboVestList = item.vestList;
          }
          if (item.platform == "8") {
            this.fctxVestList = item.vestList;
          }
        });
        if (this.ctripVestList.length > 0) {
          this.activeIndex = "5";
          this.searchRoomData.platform = 5;
          this.platformName = "携程";
          this.searchRoomData.hotelVestId = this.ctripVestList[0].horseVestId;
        } else if (this.eLongVestList.length > 0) {
          this.activeIndex = "1";
          this.searchRoomData.platform = 1;
          this.platformName = "艺龙";
          this.searchRoomData.hotelVestId = this.eLongVestList[0].horseVestId;
        } else if (this.fliggyVestList.length > 0) {
          this.activeIndex = "2";
          this.searchRoomData.platform = 2;
          this.platformName = "飞猪";
          this.searchRoomData.hotelVestId = this.fliggyVestList[0].horseVestId;
        } else if (this.meiTuanVestList.length > 0) {
          this.activeIndex = "3";
          this.searchRoomData.platform = 3;
          this.platformName = "美团";
          this.searchRoomData.hotelVestId = this.meiTuanVestList[0].horseVestId;
        } else if (this.qunarVestList.length > 0) {
          this.activeIndex = "4";
          this.searchRoomData.platform = 4;
          this.platformName = "去哪";
          this.searchRoomData.hotelVestId = this.qunarVestList[0].horseVestId;
        }
        else if (this.baiduVestList.length > 0) {
          this.activeIndex = "6";
          this.searchRoomData.platform = 6;
          this.platformName = "百度";
          this.searchRoomData.hotelVestId = this.baiduVestList[0].horseVestId;
        }
        else if (this.luoboVestList.length > 0) {
          this.activeIndex = "7";
          this.searchRoomData.platform = 7;
          this.platformName = "萝卜";
          this.searchRoomData.hotelVestId = this.luoboVestList[0].horseVestId;
        }
        else if (this.fctxVestList.length > 0) {
          this.activeIndex = "8";
          this.searchRoomData.platform = 8;
          this.platformName = "天下房仓";
          this.searchRoomData.hotelVestId = this.fctxVestList[0].horseVestId;
        }
        this.getRoomInfoById();
      }
    },
    //跳转日志页面
    gotoRoomLog() {
      this.$router.push({
        path: "/roomloglist",
        query: {
          id: this.searchRoomData.hotelSonId,
          hotelName: this.hotelInfoData.hotelName,
        },
      });
    },

    //一级全选
    handleCheckAllChange(e) {
      let that = this;
      this.ischeckAll = e;
      if (e == true) {
        this.indeterminate = false;
        for (let i = 0; i < that.roomBigList.length; i++) {
          that.roomBigList[i].selected = e;
          for (let j = 0; j < that.roomBigList[i].hotelRoomVestVo.length; j++) {
            that.roomBigList[i].hotelRoomVestVo[j].selected = e;
          }
        }
      } else {
        this.indeterminate = false;
        for (let i = 0; i < that.roomBigList.length; i++) {
          that.roomBigList[i].selected = e;
          for (let j = 0; j < that.roomBigList[i].hotelRoomVestVo.length; j++) {
            that.roomBigList[i].hotelRoomVestVo[j].selected = e;
          }
        }
      }
    },
    //二级全选
    handleCheckTwoChange(index, id, e) {
      let that = this;
      that.roomBigList[index].selected = e;
      if (e == false) {
        this.roomBigList[index].indeterminate = false;
      }
      if (that.roomBigList[index].hotelRoomVestVo) {
        for (
          let i = 0;
          i < that.roomBigList[index].hotelRoomVestVo.length;
          i++
        ) {
          that.roomBigList[index].hotelRoomVestVo[i].selected = e;
        }
      }
      this.getIsCheckAll();
    },
    //三级选择
    handleCheckThreeChange(topIndex, sonId, topId, e) {
      let that = this;
      let childrenArray = that.roomBigList[topIndex].hotelRoomVestVo;
      let tickCount = 0,
        unTickCount = 0,
        len = childrenArray.length;
      for (let i = 0; i < len; i++) {
        if (sonId == childrenArray[i].id) {
          childrenArray[i].selected = e;
        }
        if (childrenArray[i].selected == true) {
          tickCount++;
        }
        if (childrenArray[i].selected == false) {
          unTickCount++;
        }
      }
      if (tickCount == len) {
        that.roomBigList[topIndex].selected = true;
        that.roomBigList[topIndex].indeterminate = false;
      } else if (unTickCount == len) {
        that.roomBigList[topIndex].selected = false;
        that.roomBigList[topIndex].indeterminate = false;
      } else {
        that.roomBigList[topIndex].selected = false;
        that.roomBigList[topIndex].indeterminate = true;
      }
      that.getIsCheckAll();
    },
    //x选择引起的上层全选框变动
    getIsCheckAll() {
      let that = this;
      let tickCount = 0,
        unTickCount = 0,
        ArrLength = that.roomBigList.length;
      for (let j = 0; j < ArrLength; j++) {
        if (that.roomBigList[j].selected == true) {
          tickCount++;
        }
        if (that.roomBigList[j].selected == false) {
          unTickCount++;
        }
      }
      if (tickCount == ArrLength) {
        //二级全勾选
        that.ischeckAll = true;
        that.indeterminate = false;
      } else if (unTickCount == ArrLength) {
        //二级全不勾选
        that.ischeckAll = false;
        that.indeterminate = false;
      } else {
        that.ischeckAll = false;
        that.indeterminate = true; //添加一级不确定状态
      }
    },
    //格式化时间
    initDate(date) {
      date = new Date(date);

      let year = date.getFullYear();
      let mouth = date.getMonth() + 1;
      if (mouth < 10) {
        mouth = "0" + mouth;
      }
      let day = date.getDate();
      if (day < 10) {
        day = "0" + day;
      }
      return `${year}-${mouth}-${day}`;
    },
    //前一天数据
    getPredata() {
      if (this.choose_week == "按周") {
        // if (
        //   new Date(this.searchRoomData.chooseDate).getTime() <
        //   new Date().getTime() + 6 * 24 * 60 * 60 * 1000
        // ) {
        //   this.$message.warning("不能选择以前的时间");
        // } else {
        this.searchRoomData.chooseDate = this.initDate(
          new Date(
            new Date(this.searchRoomData.chooseDate).getTime() -
            7 * 24 * 60 * 60 * 1000
          )
        );
        this.changeDate(this.searchRoomData.chooseDate);
        //  }
      } else if (this.choose_week == "按月") {
        // if (
        //   new Date(this.searchRoomData.chooseDate).getTime() <
        //   new Date().getTime() + 29 * 24 * 60 * 60 * 1000
        // ) {
        //   this.$message.warning("不能选择以前的时间");
        // } else {
        this.searchRoomData.chooseDate = this.initDate(
          new Date(
            new Date(this.searchRoomData.chooseDate).getTime() -
            30 * 24 * 60 * 60 * 1000
          )
        );
        this.changeDate(this.searchRoomData.chooseDate);
        // }
      }
    },
    //后一天数据
    getNestdata() {
      if (this.choose_week == "按周") {
        this.searchRoomData.chooseDate = this.initDate(
          new Date(
            new Date(this.searchRoomData.chooseDate).getTime() +
            7 * 24 * 60 * 60 * 1000
          )
        );
        this.changeDate(this.searchRoomData.chooseDate);
      } else if (this.choose_week == "按月") {
        this.searchRoomData.chooseDate = this.initDate(
          new Date(
            new Date(this.searchRoomData.chooseDate).getTime() +
            30 * 24 * 60 * 60 * 1000
          )
        );
        this.changeDate(this.searchRoomData.chooseDate);
      }
    },
    //日期改变
    changeDate(dat) {
      //  console.log(dat);
      let date = new Date(dat);
      // if (date.getTime() < new Date().getTime()-24*60*60*1000) {
      //   this.$message.warning("不能选择以前的时间");
      //   // this.searchRoomData.chooseDate = new Date();
      // } else {
      let week = date.getDay();
      //   console.log(week);
      this.weeks = [];
      // if (week == 0) {
      //   this.weeks.push({ week: "周日", id: 0 });
      //   this.weeks.push({ week: "周一", id: 1 });
      //   this.weeks.push({ week: "周二", id: 2 });
      //   this.weeks.push({ week: "周三", id: 3 });
      //   this.weeks.push({ week: "周四", id: 4 });
      //   this.weeks.push({ week: "周五", id: 5 });
      //   this.weeks.push({ week: "周六", id: 6 });
      // } else if (week == 1) {
      //   this.weeks.push({ week: "周一", id: 0 });
      //   this.weeks.push({ week: "周二", id: 1 });
      //   this.weeks.push({ week: "周三", id: 2 });
      //   this.weeks.push({ week: "周四", id: 3 });
      //   this.weeks.push({ week: "周五", id: 4 });
      //   this.weeks.push({ week: "周六", id: 5 });
      //   this.weeks.push({ week: "周日", id: 6 });
      // } else if (week == 2) {
      //   this.weeks.push({ week: "周二", id: 0 });
      //   this.weeks.push({ week: "周三", id: 1 });
      //   this.weeks.push({ week: "周四", id: 2 });
      //   this.weeks.push({ week: "周五", id: 3 });
      //   this.weeks.push({ week: "周六", id: 4 });
      //   this.weeks.push({ week: "周日", id: 5 });
      //   this.weeks.push({ week: "周一", id: 6 });
      // } else if (week == 3) {
      //   this.weeks.push({ week: "周三", id: 0 });
      //   this.weeks.push({ week: "周四", id: 1 });
      //   this.weeks.push({ week: "周五", id: 2 });
      //   this.weeks.push({ week: "周六", id: 3 });
      //   this.weeks.push({ week: "周日", id: 4 });
      //   this.weeks.push({ week: "周一", id: 5 });
      //   this.weeks.push({ week: "周二", id: 6 });
      // } else if (week == 4) {
      //   this.weeks.push({ week: "周四", id: 0 });
      //   this.weeks.push({ week: "周五", id: 1 });
      //   this.weeks.push({ week: "周六", id: 2 });
      //   this.weeks.push({ week: "周日", id: 3 });
      //   this.weeks.push({ week: "周一", id: 4 });
      //   this.weeks.push({ week: "周二", id: 5 });
      //   this.weeks.push({ week: "周三", id: 6 });
      // } else if (week == 5) {
      //   this.weeks.push({ week: "周五", id: 0 });
      //   this.weeks.push({ week: "周六", id: 1 });
      //   this.weeks.push({ week: "周日", id: 2 });
      //   this.weeks.push({ week: "周一", id: 3 });
      //   this.weeks.push({ week: "周二", id: 4 });
      //   this.weeks.push({ week: "周三", id: 5 });
      //   this.weeks.push({ week: "周四", id: 6 });
      // } else if (week == 6) {
      //   this.weeks.push({ week: "周六", id: 0 });
      //   this.weeks.push({ week: "周日", id: 1 });
      //   this.weeks.push({ week: "周一", id: 2 });
      //   this.weeks.push({ week: "周二", id: 3 });
      //   this.weeks.push({ week: "周三", id: 4 });
      //   this.weeks.push({ week: "周四", id: 5 });
      //   this.weeks.push({ week: "周五", id: 6 });
      // }

      this.getRoomInfoById();
      // }
    },
    //周或者月发生改变
    changeWeekorMonth(data) {
      //  console.log(data);
      if (this.searchRoomData.hotelVestId) {
        this.getRoomInfoById();
      }
    },
    //打开新增模态框
    openAddDia() {
      if (!this.searchRoomData.hotelSonId) {
        this.$alert("请先选择酒店");
      } else {
        this.$refs.addroom.open(this.searchRoomData.hotelSonId);
      }
    },
    //打开根据艺龙房型添加的模态框
    openElongtypeAddDia(){
      if (!this.searchRoomData.hotelSonId) {
        this.$alert("请先选择酒店");
      } else {
        this.$refs.elongtypeaddroom.open(this.searchRoomData.hotelSonId);
      }

    },
    //打开设置/恢复售卖模态框
    openEditDiaOne(data) {
      this.$refs.editroomone.open(data);
    },
    //打开批量改房态模态框
    openEditDiaTwo() {
      if (this.roomBigList.length == 0) {
        this.$alert("没有可修改房态的内容");
      } else {
        let selectRoomIdArr = [];
        let count = 0;
        for (let i = 0; i < this.roomBigList.length; i++) {
          for (let j = 0; j < this.roomBigList[i].hotelRoomVestVo.length; j++) {
            if (this.roomBigList[i].hotelRoomVestVo[j].selected == true) {
              count++;
              selectRoomIdArr.push(
                this.roomBigList[i].hotelRoomVestVo[j].roomId
              );
            }
          }
        }
        if (count == 0) {
          this.$alert("请选择需要修改房态的售卖房型");
        } else {
          this.$refs.editroomtwo.open(
            this.searchRoomData.hotelSonId,
            selectRoomIdArr
          );
        }
      }
    },
    //打开批量改房价的模态框
    openEditDiaForRoomPrice() {
      if (this.roomBigList.length == 0) {
        this.$alert("没有可修改房态的内容");
      } else {
        let count = 0;
        let dataArr = [];
        for (let i = 0; i < this.roomBigList.length; i++) {
          for (let j = 0; j < this.roomBigList[i].hotelRoomVestVo.length; j++) {
            if (this.roomBigList[i].hotelRoomVestVo[j].selected == true) {
              count++;
              dataArr.push(this.roomBigList[i].hotelRoomVestVo[j]);
            }
          }
        }
        if (count == 0) {
          this.$alert("请选择需要修改房价的售卖房型(不支持月租房)");
        } else {
          //  console.log(this.searchRoomData.hotelSonId);
          //console.log(dataArr);
          this.$refs.editroomprice.open(
            this.searchRoomData.hotelSonId,
            dataArr
          );
        }
      }
    },
    //打开批量设置售卖房型模态框
    openEditDiaForRoomsType() {
      if (this.roomBigList.length == 0) {
        this.$alert("没有可修改房态的内容");
      } else {
        let selectRoomIdArr = [];
        let count = 0;
        for (let i = 0; i < this.roomBigList.length; i++) {
          for (let j = 0; j < this.roomBigList[i].hotelRoomVestVo.length; j++) {
            if (this.roomBigList[i].hotelRoomVestVo[j].selected == true) {
              count++;
              selectRoomIdArr.push(
                this.roomBigList[i].hotelRoomVestVo[j].roomId
              );
            }
          }
        }
        if (count == 0) {
          this.$alert("请选择需要修改房态的售卖房型");
        } else {
          this.$refs.editroomstype.open(
            this.searchRoomData.hotelSonId,
            selectRoomIdArr
          );
        }
      }
    },
    //列表中点击编辑打开单个售卖房型 功能 修改成 修改房型名称
    openEditDiaForRoomsTypeTwo(room) {
      this.$refs.editroomstype.open(this.searchRoomData.hotelSonId, room.roomId);

     console.log(room)
    

    },

    //
    openEditRoomNameDia(room){
      this.$refs.editroomnamedia.open(room)
    },

    //打开二次拦截设置模态框
    openEditInterceptRule(type) {
      if (this.roomBigList.length == 0) {
        this.$alert("没有可拦截的内容");
      } else {
        let selectRoomIdArr = [];
        let count = 0;
        for (let i = 0; i < this.roomBigList.length; i++) {
          for (let j = 0; j < this.roomBigList[i].hotelRoomVestVo.length; j++) {
            if (this.roomBigList[i].hotelRoomVestVo[j].selected == true) {
              count++;
              selectRoomIdArr.push(
                this.roomBigList[i].hotelRoomVestVo[j].roomId
              );
            }
          }
        }
        if (count == 0) {
          this.$alert("请选择需要修改拦截的售卖房型");
        } else {
          this.$refs.editinterceptrule.open(
            this.searchRoomData.hotelSonId,
            selectRoomIdArr,
            type
          );
        }
      }
    },
    //打开设置售卖房型模态框
    openEditRoomType(data, effectiveDate, e) {
      // console.log(data.roomName);
      // console.log( e.currentTarget);
      // console.log(e.target);
      // console.log(e);

      if (this.activeIndex != "5") {
        //  console.log("其他通道");
        this.$refs.editroomtype.open(
          data.roomName,
          data.roomId,
          data.roomVestId,
          this.activeIndex,
          effectiveDate
        );
      } else {
        //  console.log("携程");
        this.$refs.editroomtypeforctrip.open(
          data.roomName,
          data.roomId,
          data.roomVestId,
          this.activeIndex,
          effectiveDate
        );
      }
    },
    //打开新增售卖房型
    openAddSellRoom(data) {
      // console.log(data);
      this.$refs.addsellroom.open(data);
    },
    //打开删除
    openDelSellRoom(roomTypeId) {
      this.$confirm("确定删除该物理房型, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.delSellRoom(roomTypeId);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    async delSellRoom(roomTypeId) {
      let { code, data } = await delSellRoom_api({
        roomTypeId: roomTypeId,
      });
      if (code == 0) {
        this.$message.success(data ? data : "操作成功");
        this.getRoomInfoById();
      }
    },
    openMatchingRoom(item) {
      //美团没有匹配功能

      if (this.activeIndex == "3") {
        // this.$alert("美团没有此功能");
        this.$refs.matchingroomqunar.open(
          item.roomTypeVestId,
          this.activeIndex
        );
      } else if (this.activeIndex == "4") {
        //匹配去哪儿
        this.$refs.matchingroomqunar.open(
          item.roomTypeVestId,
          this.activeIndex
        );
      } else if (this.activeIndex == "2") {
        //匹配飞猪的模态框
        this.$refs.matchingroom.open(
          item.roomTypeVestId,
          item.hotelSonVestId,
          this.searchRoomData.hotelVestId,
          this.activeIndex
        );
      } else if (this.activeIndex == "1") {
        //匹配艺龙的模态框
        this.$refs.matchingroom.open(
          item.roomTypeVestId,
          item.hotelSonVestId,
          this.searchRoomData.hotelVestId,
          this.activeIndex
        );
      } else if (this.activeIndex == "5") {
        this.$refs.matchingroom.open(
          item.roomTypeVestId,
          item.hotelSonVestId,
          this.searchRoomData.hotelVestId,
          this.activeIndex
        );
      } else if (this.activeIndex == "7") {
        this.$refs.matchingroom.open(
          item.roomTypeVestId,
          item.hotelSonVestId,
          this.searchRoomData.hotelVestId,
          this.activeIndex
        );
      } else if (this.activeIndex == "8") {
        this.$refs.matchingroom.open(
          item.roomTypeVestId,
          item.hotelSonVestId,
          this.searchRoomData.hotelVestId,
          this.activeIndex
        );
      }
    },

    //显示房型列表开关
    swithHoteInfo(item) {
      if (item.elIconArrow == "el-icon-arrow-down") {
        item.elIconArrow = "el-icon-arrow-up";
        item.tooltipInfo = "展开";
      } else {
        item.elIconArrow = "el-icon-arrow-down";
        item.tooltipInfo = "收起";
      }
    },
    //下线
    delRoom(roomVestId, type) {
      let typelabel;
      if (type == 0) {
        typelabel = "下线";
      } else if (type == 1) {
        typelabel = "上线";
      }
      this.$confirm(`确定${typelabel}该房型, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.delRoomFn(roomVestId, type);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    async delRoomFn(roomVestId, type) {
      let { code, data } = await delRoom_api({
        roomVestId: roomVestId,
        type: type,
      });
      if (code == 0) {
        this.$message.success(data ? data : "操作成功");
        this.getRoomInfoById();
      }
    },
    //删除
    realDelRoom(roomVestId) {
      this.$confirm("确定删除该房型, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.realDelRoomFn(roomVestId);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    async realDelRoomFn(roomVestId) {
      let { code, data } = await realDelRoom_api({
        roomVestId: roomVestId,
      });
      if (code == 0) {
        this.$message.success(data ? data : "删除成功");
        this.getRoomInfoById();
      }
    },
    delAssociatedRoom(roomVestId) {
      this.$confirm("确定删除该房型的关联, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.delAssociatedRoomFn(roomVestId);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    async delAssociatedRoomFn(roomVestId) {
      let { code, data } = await delAssociatedRoom_api({
        roomVestId: roomVestId,
      });
      if (code == 0) {
        this.$message.success(data ? data : "关联删除成功");
        this.getRoomInfoById();
      }
    },
    //重新推送、推送
    async sendRoomInfoFn(val) {
      let { code, data } = await sendRoomInfo_api({
        roomVestId: val.roomVestId,
        platform: this.searchRoomData.platform,
      });
      if (code == 0) {
        this.$message.success(data ? data : "推送成功");
        this.getRoomInfoById();
      }
    },
    //在匹配中状态下查看匹配状态
    async checkRoomVestMappingFn(hotelSonVestId, roomTypeVestId, roomVestId) {
      let { code, data } = await checkRoomVestMapping_api({
        hotelSonVestId: hotelSonVestId,
        roomTypeVestId: roomTypeVestId,
        roomVestId: roomVestId,
        platform: this.searchRoomData.platform,
      });
      if (code == 0) {
        this.$message.success(data ? data : "查看了");
      }
    },
    //列表中的开关
    changgeSwitch(etem, ctem) {
      console.log(etem);
      console.log(ctem);
      if (this.searchRoomData.platform == 5) {
        let startTime = etem.effectiveDate;
        let state = etem.roomStatus;
        let roomVestId = ctem.roomVestId;
        let roomId = ctem.roomId;
        this.upDltRp(roomVestId, roomId, state, startTime);
      } else {
        let endTime = etem.effectiveDate;
        let startTime = etem.effectiveDate;
        let state = etem.roomStatus;
        let roomVestIds = ctem.roomVestId;
        let roomIds = ctem.roomId;

        if (this.searchRoomData.platform == 1) {
          this.upYiLongRp(roomVestIds, roomIds, state, startTime, endTime);
        } else if (this.searchRoomData.platform == 2) {
          this.upFlyPigRp(roomVestIds, roomIds, state, startTime, endTime);
        } else if (this.searchRoomData.platform == 3) {
          this.upMeiTuanRp(roomVestIds, roomIds, state, startTime, endTime);
        } else if (this.searchRoomData.platform == 4) {
          this.upQunarRp(roomVestIds, roomIds, state, startTime, endTime);
        } else if (this.searchRoomData.platform == 6) {
          this.upBaiduRp(roomVestIds, roomIds, state, startTime, endTime);
        } else if (this.searchRoomData.platform == 7) {
          this.upLuoboRp(roomVestIds, roomIds, state, startTime, endTime);
        } else if (this.searchRoomData.platform == 8) {
          this.upFctxRp(roomVestIds, roomIds, state, startTime, endTime);
        }
      }
    },
    //携程开关
    async upDltRp(roomVestId, roomId, state, startTime) {
      req.changeShowLoadingF();
      this.isSwitchApi = true;
      let { code, data } = await upDltRp_api({
        roomVestId: roomVestId,
        roomId: roomId,
        state: state,
        startTime: startTime,
        endTime: startTime,
      });
      if (code == 0) {
        req.changeShowLoadingT();
        this.$message.success(data ? data : "提交成功");
        this.getRoomInfoById();
      }
    },
    //艺龙渠道提交
    async upYiLongRp(roomVestIds, roomIds, state, startTime, endTime) {
      req.changeShowLoadingF();
      this.isSwitchApi = true;
      let { code, data } = await upYiLongRp_api({
        roomVestIds: roomVestIds,
        roomIds: roomIds,
        state: state,
        startTime: startTime,
        endTime: endTime,
      });
      if (code == 0) {
        req.changeShowLoadingT();

        this.$message.success(data ? data : "提交成功");
        this.getRoomInfoById();
      }
    },
    //飞猪渠道提交
    async upFlyPigRp(roomVestIds, roomIds, state, startTime, endTime) {
      req.changeShowLoadingF();
      this.isSwitchApi = true;
      let { code, data } = await upFlyPigRp_api({
        roomVestIds: roomVestIds,
        roomIds: roomIds,
        state: state,
        startTime: startTime,
        endTime: endTime,
      });
      if (code == 0) {
        req.changeShowLoadingT();
        this.$message.success(data ? data : "提交成功");
        this.getRoomInfoById();
      }
    },
    //美团渠道提交
    async upMeiTuanRp(roomVestIds, roomIds, state, startTime, endTime) {
      req.changeShowLoadingF();
      this.isSwitchApi = true;
      let { code, data } = await upMeiTuanRp_api({
        roomVestIds: roomVestIds,
        roomIds: roomIds,
        state: state,
        startTime: startTime,
        endTime: endTime,
      });
      if (code == 0) {
        req.changeShowLoadingT();
        this.$message.success(data ? data : "提交成功");
        this.getRoomInfoById();
      }
    },
    //去哪儿渠道提交
    async upQunarRp(roomVestIds, roomIds, state, startTime, endTime) {
      req.changeShowLoadingF();
      this.isSwitchApi = true;
      let { code, data } = await upQunarRp_api({
        roomVestIds: roomVestIds,
        roomIds: roomIds,
        state: state,
        startTime: startTime,
        endTime: endTime,
      });
      if (code == 0) {
        req.changeShowLoadingT();
        this.$message.success(data ? data : "提交成功");
        this.getRoomInfoById();
      }
    },
    // 百度渠道提交
    async upBaiduRp(roomVestIds, roomIds, state, startTime, endTime) {
      req.changeShowLoadingF();
      this.isSwitchApi = true;
      let { code, data } = await upBaiduRp_api({
        roomVestIds: roomVestIds,
        roomIds: roomIds,
        state: state,
        startTime: startTime,
        endTime: endTime,
      });
      if (code == 0) {
        req.changeShowLoadingT();
        this.$message.success(data ? data : "提交成功");
        this.getRoomInfoById();
      }
    },

    // 萝卜渠道提交
    async upLuoboRp(roomVestIds, roomIds, state, startTime, endTime) {
      req.changeShowLoadingF();
      this.isSwitchApi = true;
      let { code, data } = await upLuoboRp_api({
        roomVestIds: roomVestIds,
        roomIds: roomIds,
        state: state,
        startTime: startTime,
        endTime: endTime,
      });
      if (code == 0) {
        req.changeShowLoadingT();
        this.$message.success(data ? data : "提交成功");
        this.getRoomInfoById();
      }
    },
    // 天下房仓渠道提交
    async upFctxRp(roomVestIds, roomIds, state, startTime, endTime) {
      req.changeShowLoadingF();
      this.isSwitchApi = true;
      let { code, data } = await upFctxRp_api({
        roomVestIds: roomVestIds,
        roomIds: roomIds,
        state: state,
        startTime: startTime,
        endTime: endTime,
      });
      if (code == 0) {
        req.changeShowLoadingT();
        this.$message.success(data ? data : "提交成功");
        this.getRoomInfoById();
      }
    },

    openEditRoomTypeName(roomTypeId) {
      this.$refs.editroomtypename.open(roomTypeId);
    },

    //打开重新发布物理房型dia
    openReSellRoomType(roomTypeId) {
      // console.log(item)
      this.$refs.readdroomtype.open(this.searchRoomData.hotelSonId, roomTypeId);
    },
    openReSellRoom(roomTypeId) {
      this.$refs.readdroom.open(roomTypeId);
    },

    changeIsMatching(roomVestId) {
      this.$confirm("是否将该房型改成匹配中?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.changeIsMatchingFn(roomVestId);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    async changeIsMatchingFn(roomVestId) {
      let { code, data } = await changeIsMatching_api({
        roomVestId: roomVestId,
      });
      if (code == 0) {
        this.getRoomInfoById();
        this.$message({
          message: "修改成功",
          type: "success",
        });
      }
    },
    set30MinuteCancelFn(type) {
      let str = "";
      if (type == 1) {
        str = "是否设置该酒店30分钟取消？";
      } else {
        str = "是否取消该酒店30分钟取消的规则？";
      }
      this.$confirm(str, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.set30MinuteCancelFnf(type);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    async set30MinuteCancelFnf(type) {
      let { code, data } = await set30MinuteCancel_api({
        hotelId: this.searchRoomData.hotelSonId,
        opType: type,
      });
      if (code == 0) {
        this.$message.success("操作成功");
      }
    },
    //打开去哪儿礼包模态框
    openLibao(roomVestId) {
      // this.$refs.giftbag.open(roomVestId)
      this.$refs.giftbag.open(roomVestId);
    },
    openLibaoLuobo(roomVestId){
      this.$refs.giftbagluobo.open(roomVestId);
    },
    openElongIdDia(){
      if (!this.searchRoomData.hotelSonId) {
        this.$alert("请先选择酒店");
      } else {
        this.$refs.elongiddia.open(this.searchRoomData.hotelSonId);
      }
    },
    openSynchronization(){
      if (!this.searchRoomData.hotelSonId) {
        this.$alert("请先选择酒店");
      } else {
        this.$refs.synchronizationdia.open(this.searchRoomData.hotelSonId);
      }
    },
    yilongPush(roomVestId){
      this.$confirm("是否推送艺龙？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
     .then(() => {
          this.yilongPushFn(roomVestId);
        })
     .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    async yilongPushFn(roomVestId){
      let { code, data } = await yilongPush_api({roomVestId: roomVestId});
      if (code == 0) {
        this.$message.success(data? data : "推送成功");
      }

    }
  },
};
</script>

<style lang="less" scoped>
.roomBoxOne {
  width: calc(100% - 40px);
  // width: 100%;
  margin-top: 20px;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0px 4px 8px 0px rgba(220, 228, 240, 0.42);
  border-radius: 10px;
  position: relative;
}

.checkOne {
  display: inline-block;
  margin-right: 40px;
  margin-bottom: 20px;
}

// /deep/ .el-input--mini {
//   background-color: #f6f8fb;
// }
// /deep/ .el-input__inner {
//   background-color: #f6f8fb;
// }
.roomBoxOneOff {
  width: 56px;
  height: 28px;
  line-height: 28px;
  color: #256ef1;
  cursor: pointer;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  //   display: inline-block;
  float: right;
}

.roomBoxOneHotelNameBox {
  //width: calc(100% - 40px);
  width: 100%;
  background-color: #f1f5fb;
}

.roomBoxOneHotelName {
  display: inline-block;
  cursor: pointer;
  width: 20%;
  margin: 10px auto;
  //text-align: center;
}

.roomBoxOneHotelName:hover {
  color: #256def;
}

.roomPagin {
  text-align: right;
}

.roomBoxTwo {
  margin-top: 20px;
  height: 60px;
  background: #ffffff;
  box-shadow: 0px 4px 8px 0px rgba(220, 228, 240, 0.42);
  border-radius: 10px;
  line-height: 60px;
  position: relative;
}

.roomBoxTwoHotelName {
  margin-left: 20px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  display: inline-block;
}

.roomRzBtn {
  float: right;
  margin-top: 15px;
  margin-right: 20px;
}

.roomBoxThree {
  margin-top: 20px;
}

.roomBoxThreeDetail {
  display: inline-block;
  width: 32%;
  height: 260px;
  background: #ffffff;
  box-shadow: 0px 4px 8px 0px rgba(220, 228, 240, 0.42);
  border-radius: 10px;
  vertical-align: top;
}

.roomBoxThreeDetailTwo {
  margin: 0 2%;
}

.roomBoxThreeJuxingBox {
  display: inline-block;
  vertical-align: top;
  width: 14px;
}

.roomBoxThreeJuxing {
  width: 4px;
  height: 16px;
  background: #256def;
  border-radius: 2px;
  margin-left: 10px;
  margin-top: 20px;
}

.roomBoxThreeJuxingRight {
  display: inline-block;
  width: calc(100% - 14px);
  //background-color: #222222;
}

.roomBoxThreeTag {
  margin-bottom: 20px;
  margin-left: 10px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}

.firstTag {
  margin-top: 20px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
}

.roomBoxThreeTagContent {
  margin-left: 5px;
  display: inline-block;
  // position: relative;
  // bottom: 5px;
}

.vdistpicker {
  display: inline-block;
  height: 28px;
  margin-left: 10px;

  select {
    height: 28px !important;
  }
}

// .vdistpicker > label > select{
//   height: 28px!important;
// }
// .distpicker-address-wrapper select {
//   height: 28px!important;
// }
// .vdistpicker >label > select{
//   height: 28px;
// }
.roomBoxFour {
  margin-top: 20px;
  position: relative;
  background: #ffffff;
  box-shadow: 0px 4px 8px 0px rgba(220, 228, 240, 0.42);
  border-radius: 10px;
  min-width: 1000px;
  margin-bottom: 20px;
}

.roomBoxFourRightBtn {
  position: absolute;
  right: 20px;
  top: 20px;
}

.roomBoxFourMajiaBox {
  padding-left: 20px;
}

.roomBoxFourMajia {
  display: inline-block;
  margin-right: 30px;
  margin-bottom: 20px;
  margin-top: 10px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  cursor: pointer;
}

.activeMajia {
  font-weight: bold;
  color: #256def;
}

.roomBoxFourAllMajiaBox {
  padding-bottom: 20px;
  border-bottom: 1px solid #eeeeee;
}

.line {
  border-bottom: 1px solid #eeeeee;
}

.roomBoxFourTimeBox {
  float: right;
  margin-right: 20px;
}

.roomBoxFourTimePreBox {
  height: 26px;
  width: 26px;
  display: inline-block;
  text-align: center;
  background: #f6f8fb;
  border: 1px solid #ebeff4;
  border-radius: 4px;
  vertical-align: top;
  line-height: 24px;
  color: #256def;
  font-size: 18px;
  cursor: pointer;
}

.roomBoxFourZhou {
  float: right;
  margin-right: 10px;
}

.roomBoxFourWeeksBox {
  height: 60px;
  background: #ebf1fa;
}

.roomBoxFourWeek {
  width: 70%;
  height: 60px;
  line-height: 60px;
  display: inline-block;
}

.roomBoxFourFangTitle {
  height: 40px;
  background: #f6f8fc;
  border: 1px solid #dfe3ec;
  line-height: 40px;
  position: relative;
  padding-left: 10px;
}

.roomBoxFourFangContent {
  width: 114px;
  display: inline-block;
  color: #666666;
  text-align: center;
  height: 100px;
  border: 1px solid #dfe3ec;
}

.guanfangbg {
  background: #ffcccc;
}

.kaifangbg {
  background: #a8edbd;
}

.roomBoxFourTitleIcon {
  //display: inline-block;
  float: right;
  font-size: 18px;
  color: #256def;
}

// 单元格内开关样式
/deep/.el-switch__core {
  height: 12px !important;
  width: 26px !important;

  &:after {
    height: 12px;
    width: 12px;
    position: absolute;
    top: -1px;
    left: 1px;
  }
}

/deep/ .el-switch.is-checked .el-switch__core::after {
  margin-left: -12px;
}

.roomBoxFourFangContentliwu {
  margin-right: -20px;
  margin-top: 10px;
}

.roomBoxFourFangContentqu {
  float: right;
  margin-right: 1px;
  color: #256def;
  border: #256def 1px solid;
  cursor: pointer;
}

.roomBoxFourFangContentTimer {
  margin-left: 10px;
}

.roomBoxFourFangContentThreeBox {
  display: inline-block;
  width: 33%;
  vertical-align: top;
}

.roomBoxFourFangTitleImgBox {
  display: inline-block;

  color: #999999;
  font-size: 12px;

  img {
    margin-left: 16px;
    margin-right: 5px;
  }
}

.roomBoxFourFangContentThreeBoxPrice {
  color: #ff6600;
  font-weight: bold;
  margin-top: 5px;
  width: 150%;
  text-align: center;
  position: relative;
  left: -15%;
}

.inlineBox50 {
  display: inline-block;
  width: 50%;
  //height: 50px;
  //line-height: 50px;
  height: 33px;
  line-height: 33px;

  text-align: center;
}

/deep/.el-input__inner,
/deep/.el-range-input {
  // background: #ebeff4;
  background: #f6f8fb;
}

.cuxiaobox {
  margin-left: 10px;
  color: #256def;
  border: #256def 1px solid;
  cursor: pointer;
  display: inline-block;
  width: 14px;
  font-size: 12px;
  text-align: center;
  border-radius: 2px;
}
</style>
